import React, { useEffect, useState } from "react";
import Select from "react-select";

// import SideMenu from "../sidemenu";
import "./index.scss";
// import { SidebarSection } from "../../constants/constant";
// import SideBarHeader from "../header/LoggedInHeader";
import api from "../../api";
import DishRegister from "./addOffer";
import Loader from "../Loader";
import Pagination from "./pagination";
import { DishDetailsInterface, DishInterface } from "./interface";
import DishDetailView from "./offerDetailView";
import { ToastContainer, toast } from "react-toastify";
import { Button, Spin, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SideBarHeader from "../header/LoggedInHeader";
import SideMenu from "../sidemenu";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
interface Option {
  value: string;
  label: string;
  code: string;
  isActive: boolean;
  assignedBranch: [
    {
      nameOfBranch: "";
      startDate: "";
      endDate: "";
    }
  ];
}
interface branchOption {
  value: string;
  label: string;
  code: string;
  isActive: boolean;
}
interface Item {
  _id: string;
  name: string;
  code: string;
  description: string;
  price: number;
}

function ComboOffer() {
  // const [count, setCount] = useState(1);

  // const [showNav, setShowNav] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [publishModal, setPublishModal] = useState(false);
  const [branchOptions, setBranchOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<branchOption[]>([]);
  const [restaurantId, setRestaurantId] = useState(null);
  // const [searchText, setSearchText] = useState("");
  const [dishData, setDishData] = useState<DishInterface[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [dishDetailData, setDishDetailData] =
    useState<DishDetailsInterface | null>(null);
  const [dishId, setDishId] = useState<string | null>(null);
  // const [actionType, setActionType] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [dishStatus, setDishStatus] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [showNav, setShowNav] = useState(true);
  const [filterCode, setFilterCode] = useState("all");
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const ITEMS_PER_PAGE = 10;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // update dis state
  const keys = {
    images: "images",
    videos: "videos",
  };
  const [formData, setFormData] = useState({
    typeCode: "comboSpecialOffer",
    name: "",
    description: "",
    totalPrice: null,
    offerPrice: null,
    dish: [
      {
        selectedDishId: "",
        quantity: null,
      },
    ],
    startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
  });
  useEffect(() => {
    // Set initial form data when dishDetailData is available
    if (dishDetailData) {
      const firstBranch = dishDetailData.assignedBranch && dishDetailData.assignedBranch[0];
      
      const formatDateTime = (utcDateString: string) => {
        if (!utcDateString) return "";
        const date = new Date(utcDateString);
        // Format the date to "YYYY-MM-DDTHH:MM" in local timezone
        return date.toLocaleString("sv-SE", { timeZone: userTimeZone }).slice(0, 16); 
    };

        const transformedOptions: branchOption[] = dishDetailData.assignedBranch.map(branch => {
            // Find the corresponding branch option by branchId
            const correspondingBranchOption = branchOptions.find(option => option.value === branch.branchId);

            return {
                value: branch.branchId,
                label: correspondingBranchOption ? correspondingBranchOption.label : branch.branchId, // Use the label from branchOptions or fallback to branchId
                code: correspondingBranchOption ? correspondingBranchOption.code : branch.branchId, // Use the code from branchOptions or fallback to branchId
                isActive: branch.isActive // Set the active status from dishDetailData
            };
        });

        setSelected(transformedOptions);
      setFormData({
        typeCode: "comboSpecialOffer",
        name: dishDetailData.name,
        description: dishDetailData.description,
        totalPrice: dishDetailData.totalPrice,
        offerPrice: dishDetailData.offerPrice,
        dish: dishDetailData.dish.map((dishs) => ({
          selectedDishId: dishs._id, // Assuming _id should be mapped to selectedDishId
          quantity: dishs.selectedQuantity, // Use the first ingredient's quantity or 0 if not available
        })),
        startDate: formatDateTime(firstBranch ? firstBranch.startDate : ""),
            endDate: formatDateTime(firstBranch ? firstBranch.endDate : ""),
            assignedBranch: dishDetailData.assignedBranch.map((branch) => ({
                branchId: branch.branchId,
            })),
      });
      console.log("0000");
      console.log(formData);
    }
  }, [dishDetailData]);
  useEffect(() => {
    if (isPublishModalOpen) {
      const now = new Date();
      const in24Hours = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      
      const formattedNow = now.toISOString().slice(0, 16);
      const formattedIn24Hours = in24Hours.toISOString().slice(0, 16);

      setFormData((prevFormData) => ({
        ...prevFormData,
        assignedBranch: prevFormData.assignedBranch.map((branch) => ({
          ...branch,
          startDate: branch.startDate || formattedNow,
          endDate: branch.endDate || formattedIn24Hours,
        })),
      }));
    }
  }, [isPublishModalOpen]);
  const SetPayloaddata = (data: any) => {
    console.log("-----===");
    console.log(data);
    const dishData = data.dish.map((dish: any) => ({
      selectedDishId: dish.selectedDishId || "",
      quantity: dish.quantity || 0,
    }));
    setFormData({
      typeCode: "comboSpecialOffer",
      name: data.name || "",
      description: data.description || "",
      totalPrice: data.totalPrice || 0,
      offerPrice: data.offerPrice || 0,
      dish: dishData,
      startDate: "",
      endDate: "",
      assignedBranch: [
        {
          branchId: "",
          // startDate: "",
          // endDate: "",
        },
      ],
    });
  };

  const [imageList, setImageList] = useState([]);
const [videoList, setVideoList] = useState([]);
const [isLoading, setIsLoading] = useState(false);

const handleFileChange = async (key, { fileList }) => {
  if (key === "images") {
    let sizeErrorShown = false;

    // Filter valid images by checking file size for new uploads only (those with originFileObj)
    const validImages = await Promise.all(
      fileList
        .filter((file) => file.originFileObj) // Only process new files
        .map((file) => {
          return new Promise((resolve) => {
            // Check file size
            if (file.size / 1048576 > 1) {
              if (!sizeErrorShown) {
                message.error("Image size cannot exceed 1 MB.");
                sizeErrorShown = true; // Show error only once
              }
              resolve(null); // Exclude the large image
            } else {
              resolve(file); // Include valid image
            }
          });
        })
    );

    // If there is a valid image, replace the existing one
    if (validImages.filter((image) => image !== null).length > 0) {
      setImageList(validImages.filter((image) => image !== null).slice(0, 1)); // Only one image
    } 
    // If the new file is invalid (i.e., greater than 1 MB), we do nothing and keep the previous image.
  }
};


  const handleUpload = async () => {
    if (imageList.length === 0 && videoList.length === 0) {
      message.error("Please select at least one image or video.");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();

      imageList.forEach((image) => {
        formData.append("images", image.originFileObj);
      });

      videoList.forEach((video) => {
        formData.append("videos", video.originFileObj);
      });
      const previousImageUrls = imageList
        .filter((image) => !image.originFileObj && image.url) // Only images without originFileObj (old images)
        .map((image) => image.url) // Extract the URLs
        .join(","); // Join URLs with a comma

      // Append the concatenated URLs string to 'imageUrls' key
      if (previousImageUrls) {
        formData.append("imageUrls", previousImageUrls);
      }

      const response = await fetch(`/api/utility/special-offer/${dishId}`, {
        method: "PUT",
        headers: {
          authorization: "authorization-text",
        },
        body: formData,
      });

      if (response.ok) {
        message.success("Files uploaded successfully");

        navigate("/today-combo-offers");
        dishListFetchData(currentPage, filterCode);
        handleCloseUpload();
        setImageList([]);
        setVideoList([]);
        setDishId(null);
      } else {
        message.error("File upload failed");
        dishListFetchData(currentPage, filterCode);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    } finally {
      setIsLoading(false);
    }
  };
  const handleRemove = (file) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (confirmDelete) {
      setImageList([]); // Remove the single image
      message.success("Image deleted successfully.");
    } else {
      return false; // Prevent removal if user cancels
    }
  };
  

  const handleClose = () => {
    setShowModal(false);
    setDishId(null);
  };
  const handleCloseUpload = () => {
    setUploadImageModal(false);
    setDishId(null);
    setImageList([]);
    setVideoList([]);
  };
  const handleShow = (itemId: string | null) => {
    setShowModal(true);
    setDishId(itemId!);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
    setDishId(null);
  };
  const handleDeleteShow = (itemId: string) => {
    setShowDeleteModal(true);
    setDishId(itemId!);
  };
  const handleUploadShow = (itemId: string) => {
    setImageList([]);
    setVideoList([]);
    setUploadImageModal(true);
    setDishId(itemId!);
  };
  const handleRegisterClose = () => {
    dishListFetchData(currentPage, filterCode);
    setShowRegisterModal(false);
  };
  const handleRegisterShow = () => {
    setShowRegisterModal(true);
    setPublishModalOpen(false);
  };
  const handleUpdateClose = () => {
    setShowUpdateModal(false);
    setDishId(null);
  };
  const handleUpdateShow = (itemId: string) => {
    setUpdatePublishModalOpen(false);
    setShowUpdateModal(true);
    setDishId(itemId!);
  };

  const handleStatusUpdateClose = () => setShowStatusUpdateModal(false);
  const handleStatusUpdateShow = (itemId: string) => {
    setShowStatusUpdateModal(true);
    setDishId(itemId!);
  };
  const updateStatus = async () => {
    setLoading(true);
    console.log(" update status ");
    try {
      await api
        .put(
          "/api/restaurant/special-offer/status/" +
            dishId +
            "?statusCode=" +
            dishStatus
        )
        .then(function (response) {
          message.success("Status updated Successfully!");
          setLoading(false);
          console.log(response.data);
          handleStatusUpdateClose();
          setDishId(null);
          dishListFetchData(currentPage, filterCode);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          message.error("Error in api call!");
          setLoading(false);
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const dishListFetchData = async (page: number, filter: string) => {
    try {
      console.log("fetch details");
      setLoading(true);
      const response = await api.get(
        `/api/restaurant/special-offer/?page=${page}&limit=${ITEMS_PER_PAGE}&typeCode=comboSpecialOffer&filterCode=${filter}`
      );
      const dishData = response.data;
      const totalCount = dishData.data.totalCount;
      console.log("dishData", dishData.data.specialOffer);
      setDishData(dishData.data.specialOffer || []);
      setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
    } catch (error: any) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishListFetchData(currentPage, filterCode);
  }, [currentPage, filterCode]);

  const dishDetailFetchData = async (id: string | null) => {
    try {
      setLoading(true);
      if (id !== null) {
        const response = await api.get(
          `/api/restaurant/special-offer/${id}?typeCode=comboSpecialOffer`
        );
        const dishDetailData = response.data;
        setDishDetailData(dishDetailData.data);
        console.log("dishDetailData==>", dishDetailData.data);
        const existingImages = dishDetailData.data.imageAttachement.map(
          (image: any) => ({
            uid: image._id, // Use _id as uid
            name: image.url.split("/").pop(), // Extract the file name from the URL
            status: "done", // Mark as already uploaded
            url: image.url, // Use the URL from dishDetailData
            originFileObj: null, // Not needed for already uploaded images
          })
        );

        // Now set the imageList after data is fetched
        setImageList(existingImages);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dishDetailFetchData(dishId);
  }, [dishId]);

  const handleDelete = async () => {
    try {
      if (dishId !== null) {
        setLoading(true);
        const response = await api.delete(
          `/api/restaurant/special-offer/${dishId}`
        );
        setDishId(null);
        await dishListFetchData(currentPage, filterCode);
        handleDeleteClose();
        message.success(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting restaurant:", error);
      message.error("Can't proceed with deletion, Selected offer is attached to active branches");
      
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "offerPrice" || name === "totalPrice"
          ? parseInt(value, 10)
          : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const updateData = {
      typeCode: "comboSpecialOffer",
      name: formData.name,
      offerPrice: formData.offerPrice,
      description: formData.description,
      totalPrice: formData.totalPrice,
      dish: formData.dish.map((dish: any) => ({
        selectedDishId: dish.selectedDishId,
        quantity: dish.quantity,
      })),
      // branches: formData.branches,
    };

    try {
      setLoading(true);

      const response = await api.put(
        `/api/restaurant/special-offer/${dishId}`,
        updateData
      );

      console.log("Updated dish response", response);
      message.success(response.data.message);
      dishListFetchData(currentPage, filterCode);
      handleUpdateClose();
    } catch (error: any) {
      console.error("There was an error during update!", error.response.data);

      if (error.response) {
        message.error(error.response.data.message);
      } else {
        message.error("Please fill out all required fields.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  const handleAddDish = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: [...prevFormData.dish, { selectedDishId: "", quantity: 0 }],
    }));
  };

  const handleRemoveDish = (index: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.filter((_, i) => i !== index),
    }));
  };

  const handleDishesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedDishes = formData.dish.map((dish, i) => {
      if (i === index) {
        return {
          ...dish,
          [name]: name === "quantity" ? parseFloat(value) : value,
        };
      }
      return dish;
    });

    setFormData({
      ...formData,
      dish: updatedDishes,
    });
  };
  

  const handleUploadshow = () => {
    setPublishModalOpen(false);
    setUploadModalOpen(true);
    setShowRegisterModal(false);
  };
  const handleUploadClose = () => {
    setUploadModalOpen(false);
  };

  const handlePublishShow = () => {
    setPublishModalOpen(true);
    setShowRegisterModal(false);
    setUploadModalOpen(false);
    setShowUpdateModal(false);
    setSelected([]);
  };
  const handlePublishClose = () => {
    setPublishModalOpen(false);
  };

  const navigate = useNavigate();
  const openComboOfferPage = () => {
    navigate("/today-combo-offers");
  };
  const openDiscountOfferPage = () => {
    navigate("/today-discounted-offers");
  };

  const [dishOptions, setDishOptions] = useState<Option[]>([]);
  const handleChange = (selectedOption: any, index: number) => {
    const selectedValue = selectedOption ? selectedOption.value : "";
    setFormData((prevFormData) => ({
      ...prevFormData,
      dish: prevFormData.dish.map((dish, i) =>
        i === index ? { ...dish, selectedDishId: selectedValue } : dish
      ),
    }));
  };
  const getAllActiveDishes = async () => {
    try {
      const response = await api.get(
        "/api/restaurant/dish/active?page=1&limit=1000"
      );
      const formattedOptions = response.data.data.dish.map((item: Item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
        description: item.description,
      }));
      setDishOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    getAllActiveDishes();
  }, []);
  const [menuId, setMenuId] = useState("");
  const SetMenuID = (menuId: string) => {
    if (menuId !== null || 0) {
      handlePublishShow();
      setMenuId(menuId);
      console.log("Menu id");
      console.log(menuId);
    }
  };
  const handleBranchChange = (selected) => {
    setSelected(selected);
    console.log(selected);
    setFormData((prevFormData) => {
      const newBranches = [...prevFormData.assignedBranch];
      newBranches.forEach((branch, index) => {
        branch.branchId = selected.map((option) => option.value);
      });
      return {
        ...prevFormData,
        assignedBranch: newBranches,
      };
    });
  };

  const handleRemoveBranch = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      assignedBranch: prevFormData.assignedBranch.filter((_, i) => i !== index),
    }));
  };

  const handleAddBranch = () => {
    const now = new Date();
    const futureDate = new Date(now);
    futureDate.setHours(futureDate.getHours() + 24);
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      assignedBranch: [
        ...prevFormData.assignedBranch,
        {
          branchId: "",
          startDate: formatDate(now),
          endDate: formatDate(futureDate),
        },
      ],
    }));
  };
  

  useEffect(() => {
    if (restaurantId) {
      console.log("logged restaurant iddd ", restaurantId);
      getAllBranches();
    }
  }, [restaurantId]);

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
    }
  }, []);

  const getAllBranches = async () => {
    try {
      const response = await api.get(
        "api/identity/restaurant-branch/?page=1&limit=100&restaurantId=" +
          restaurantId
      );
      console.log("success response", response.data);

      const formattedOptions = response.data.data.branch.map((item) => ({
        value: item._id,
        label: item.name,
        code: item.code,
      }));
      setBranchOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error("Error fetching dish data!");
    }
  };
  const submitPublish = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Function to convert local date to UTC
    const convertToUTC = (dateString, timeZone) => {
      const localDate = new Date(dateString);
      const utcDate = new Date(localDate.toLocaleString('en-US', { timeZone }));
      return utcDate.toISOString();  // Convert to ISO string in UTC
  };

  // Convert the local times to UTC based on the user's time zone
  const convertedStartDate = convertToUTC(formData.startDate, userTimeZone);
  const convertedEndDate = convertToUTC(formData.endDate, userTimeZone);

    const data = {
      assignedBranch: formData.assignedBranch.map((branch) => ({
        branchId: branch.branchId,
      })),
    };
    const branchId = data.assignedBranch[0].branchId;

    let finalArray = [];

    for (let i = 0; i < branchId.length; i++) {
      let obj = {
        branchId: branchId[i],
        startDate: convertedStartDate,
            endDate: convertedEndDate,
      };
      finalArray.push(obj);
    }
    try {
      console.log("formData:", formData);
      console.log("dishDetailData:", dishDetailData);

      const validDishes = formData.dish
        .filter(dish => dish.selectedDishId && dish.selectedDishId.trim() !== "");
      const payload = {
        assignedBranch: finalArray,
        name: formData.name,
        description: formData.description,
        totalPrice: formData.totalPrice,
        typeCode: "comboSpecialOffer",
        offerPrice: formData.offerPrice,
        dish: validDishes.length > 0 
            ? validDishes.map(dish => ({
                selectedDishId: dish.selectedDishId,
                quantity: dish.quantity,
            }))
            : [],
      };

      console.log("Payload to be sent:", payload);

      const response = await api.put(
        `/api/restaurant/special-offer/${menuId}`,
        payload
      );
      message.success("Menu published successfully!");
      handlePublishClose();
      handleUploadShow(menuId);
      dishListFetchData(currentPage, filterCode);

      setFormData({
        typeCode: "comboSpecialOffer",
        name: "",
        totalPrice: 0,
        description: "",
        offerPrice: 0,
        dish: [
          {
            selectedDishId: "",
            quantity: null,
          },
        ],
        startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
      });
    } catch (error) {
      console.error("Error publishing menu:", error);
      message.error("Error publishing menu!");
    }
  };
  const [isUpdatePublishModalOpen, setUpdatePublishModalOpen] = useState(false);
  const handleUpdatePublishShow = () => {
    setUpdatePublishModalOpen(true);
    setShowUpdateModal(false);
  };

  const handleUpdatePublishClose = () => {
    setUpdatePublishModalOpen(false);
  };

  const submitUpdatePublish = async (event) => {
    event.preventDefault();
    // Function to convert local date to UTC
    const convertToUTC = (dateString, timeZone) => {
      const localDate = new Date(dateString);
      const utcDate = new Date(localDate.toLocaleString('en-US', { timeZone }));
      return utcDate.toISOString();  // Convert to ISO string in UTC
  };

  // Convert the local times to UTC based on the user's time zone
  const convertedStartDate = convertToUTC(formData.startDate, userTimeZone);
  const convertedEndDate = convertToUTC(formData.endDate, userTimeZone);
    let finalArray = [];

    for (let i = 0; i < selected.length; i++) {
      let obj = {
        branchId: selected[i].value,
        startDate: convertedStartDate,
        endDate: convertedEndDate,
      };
      finalArray.push(obj);
    }
    try {
      const payload = {
        typeCode: "comboSpecialOffer",
        assignedBranch: finalArray,
        name: formData.name,
        description: formData.description,
        totalPrice: formData.totalPrice,
        offerPrice: formData.offerPrice,
        dish: formData.dish,
      };

      const response = await api.put(
        `/api/restaurant/special-offer/${dishId}`,
        payload
      );
      message.success("Menu published successfully!");

      
      dishListFetchData(currentPage, filterCode);
      handleUpdatePublishClose();
      setDishId(null);

      setFormData({
        typeCode: "comboSpecialOffer",
        name: "",
        description: "",
        totalPrice: null,
        offerPrice: null,
        dish: [
          {
            selectedDishId: "",
            quantity: null,
          },
        ],
        startDate: "",
    endDate: "",
    assignedBranch: [
     
    ],
      });
    } catch (error) {
      console.error("Error publishing menu:", error);
      message.error("Error publishing menu!");
    }
  };
  const formatDateTime = (dateString) => {
    if (!dateString) {
      return ""; // Return empty string if the date is empty
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ""; // Return empty string if the date is invalid
    }
    return date.toISOString().slice(0, 16); // Format the date to "YYYY-MM-DDTHH:MM"
  };
  const handleOfferChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setFilterCode(selectedValue);
    setCurrentPage(1);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      const updatedBranches = prevFormData.assignedBranch.map((branch) => ({
        ...branch,
        [name]: value,
      }));

      return {
        ...prevFormData,
        [name]: value, // Update the top-level startDate or endDate
        assignedBranch: updatedBranches,
      };
    });
  };
  useEffect(() => {
    if (isPublishModalOpen) {
      const now = new Date(); // Get the current local time
      const in24Hours = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours to the current time

      // Format both dates to "YYYY-MM-DDTHH:mm" using local time
      const formatDateToLocal = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}`;
      };

      const formattedNow = formatDateToLocal(now);
      const formattedIn24Hours = formatDateToLocal(in24Hours);

      // Update form data with startDate and endDate
      setFormData((prevFormData) => ({
        ...prevFormData,
        startDate: prevFormData.startDate || formattedNow, // Set startDate if it's not already set
        endDate: prevFormData.endDate || formattedIn24Hours, // Set endDate if it's not already set
      }));
    }
}, [isPublishModalOpen]);

  return (
    <div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          <div className="pt-4 pb-4">
            <section className="main shadow-sm">
              <div className="row mb-4">
                <br />
                <div className="col d-flex justify-content-between">
                  <div className="col-md-6 mb-3">
                    <h4>Combo Dealz</h4>
                  </div>
                  {/* <div className="col-md-5 mb-3"><form className="form-inline my-2 my-lg-0 ">
                    <input
                      className="form-control mr-sm-2 col-md-6"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </form></div> */}
                  <div>
                  <select
                      className="form-control down-arrow"
                      onChange={handleOfferChange}
                      style={{border:"1px solid"}}
                    >
                      <option value="all">All</option>
                      <option value="todays">Todays</option>
                      <option value="expired">Expired</option>
                      <option value="Upcoming">Upcoming</option>

                    </select>
                  </div>
                  <div>
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => openComboOfferPage()}
                    >
                      <b>Combo dealz</b>
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-outline-warning"
                      type="button"
                      onClick={() => openDiscountOfferPage()}
                    >
                      <b>flash dealz</b>
                    </button>
                  </div>

                  <div>
                    <button className="btn addbtn" onClick={handleRegisterShow}>
                      <i className="bi bi-clipboard-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          <th scope="col">#</th>
                          <th scope="col">NAME OF THE COMBO OFFER</th>

                          <th scope="col">ACTUAL PRICE ({defaultCurrency})</th>
                          <th scope="col">OFFER PRICE ({defaultCurrency})</th>
                          {/* <th scope="col">STARTING DATE & TIME</th>
                    <th scope="col">END DATE & TIME</th> */}
                          <th scope="col">STATUS</th>

                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dishData.map((item, index) => (
                          <tr key={item._id}>
                            <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.totalPrice}</td>
                            <td>{item.offerPrice}</td>

                            <td>
                              {item.status === "active" ? (
                                <span className="badge bg-success rounded-pill">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "suspended" ? (
                                <span className="badge bg-error rounded-pill">
                                  Suspended
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "creating" ? (
                                <span className="badge bg-warning rounded-pill">
                                  Creating
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              {item.status === "waiting_for_approval" ? (
                                <span className="badge bg-warning rounded-pill">
                                  waitingForApproval
                                </span>
                              ) : (
                                <span className="badge bg-error rounded-pill"></span>
                              )}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn"
                                onClick={() => handleStatusUpdateShow(item._id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            </td>

                            <td className="p-1 d-flex">
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "view");
                                  handleShow(item._id);
                                }}
                              >
                                <i className="bi bi-eye"></i>
                              </button>{" "}
                              &nbsp;
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "edit");
                                  handleUpdateShow(item._id);
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              {item.status === 'active' ? (
                                <button
                                  className="btn-action rounded-circle act-btn m-1"
                                  onClick={() => {
                                    message.error("Cannot delete: offer is active.");
                                  }}
                                >
                                  <i className="bi bi-trash "></i>
                                </button>
                              ) : (
                                <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  handleDeleteShow(item._id);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                              )}
                              
                              <button
                                className="btn-action rounded-circle act-btn m-1"
                                onClick={() => {
                                  // viewDishDetails(item._id, "delete");
                                  handleUploadShow(item._id);
                                }}
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className=" d-flex  justify-content-end pe-md-5 ">
                    {!loading && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Combo Offer Details
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <>
                <DishDetailView
                  dishDetailData={dishDetailData}
                  handleClose={handleClose}
                  restaurantId={restaurantId}
                  defaultCurrency={defaultCurrency}
                />
              </>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Delete Combo Dealz?
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Are you sure you want to delete?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showStatusUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update status?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleStatusUpdateClose}
                ></button>
              </div>
              <div className="delete-text">
                <div className="mb-12 col-md-12">
                  <label className="form-label">
                    Select status <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <select
                      className="form-control down-arrow"
                      value={dishStatus}
                      onChange={(e) => setDishStatus(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Status
                      </option>
                      <option value="creating">Creating</option>

                      <option value="active">Active</option>

                      <option value="suspended">Suspended</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleStatusUpdateClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRegisterModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Combo Offers
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleRegisterClose}
                ></button>
              </div>
              <>
                <DishRegister
                  handleRegisterClose={handleRegisterClose}
                  SetMenuID={SetMenuID}
                  SetPayloaddata={SetPayloaddata}
                />
              </>
            </div>
          </div>
        </div>
      )}
      {showUpdateModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {loading && <Loader />}
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Combo Offer
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdateClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="userName" className="form-label">
                        Name Of The Combo Offer
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Name Of The Combo Offer"
                        name="name"
                        className="form-control"
                        id="userName"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          rows={2}
                          placeholder="Enter description about the offer"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          {formData.dish.map((dish, index) => (
                            <tr key={index}>
                              <td>
                                <label className="form-label">
                                  Select The Dish
                                  <span className="text-danger"> *</span>
                                </label>
                                <Select
                                  value={dishOptions.find(
                                    (option) =>
                                      option.value === dish.selectedDishId
                                  )}
                                  onChange={(selectedOption) =>
                                    handleChange(selectedOption, index)
                                  }
                                  options={dishOptions}
                                  getOptionLabel={(option) => option.label}
                                  getOptionValue={(option) => option.value}
                                  isClearable
                                  placeholder="Select"
                                />
                              </td>
                              <td>
                                <label>Quantity</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Quantity"
                                  name="quantity"
                                  value={dish.quantity}
                                  onChange={(e) => handleDishesChange(e, index)}
                                />
                              </td>
                              <td>
                                {index > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-danger rounded-circle"
                                    onClick={() => handleRemoveDish(index)}
                                  >
                                    <i className="bi bi-file-minus"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-1">
                      <button
                        type="button"
                        className="btn btn-primary rounded-circle"
                        onClick={handleAddDish}
                      >
                        <i className="bi bi-file-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="totalPrice" className="form-label">
                        Total Price ({defaultCurrency})
                        
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Total Price"
                        name="totalPrice"
                        className="form-control"
                        id="totalPrice"
                        value={formData.totalPrice}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="offerPrice" className="form-label">
                        Offer Price ({defaultCurrency})
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Offer Price"
                        name="offerPrice"
                        className="form-control"
                        id="offerPrice"
                        value={formData.offerPrice}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleUpdatePublishShow}
                  >
                    Save & Next <i className="bi bi-caret-right-fill"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isPublishModalOpen && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Publish Combo offer
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handlePublishClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <div className=" row">
                              <div className=" col-md-4 mb-3">
                                <label>
                                  Select Branch
                                  <span className="text-danger"> *</span>
                                </label>
                                <MultiSelect
                                  options={branchOptions}
                                  value={selected}
                                  onChange={handleBranchChange}
                                  labelledBy="Select Branch"
                                  className="scrollable-dropdown"
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>Start Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="startDate"
                                  value={formData.startDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter Start Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>End Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="endDate"
                                  value={formData.endDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter End Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={handlePublishClose}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitPublish}
                    >
                      Save & Next <i className="bi bi-caret-right-fill"></i>
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {uploadImageModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Upload Combo Dealz image
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseUpload}
                ></button>
              </div>
              <section className="main shadow-sm">
                {isLoading ? (
                  <div className="text-center my-4">
                    <Spin tip="Uploading... Please wait" />
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 text-center">
                      <Upload
                          multiple={false} // Allow only one image to be uploaded
                          listType="picture-card" // Display images as thumbnails
                          beforeUpload={() => false} // Disable auto upload
                          fileList={imageList} // Use the imageList state
                          onChange={(info) => handleFileChange("images", info)} // Handle file change
                          onRemove={handleRemove} // Handle remove action
                        >
                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "103px" }}
                            icon={<UploadOutlined />}
                          >
                            <b>Upload</b>
                            <span className="text-danger"> *</span>
                          </Button>
                        </Upload>

                      </div>
                      {/* <div className="col-lg-6 col-md-6 text-center">
                        <Upload
                          multiple
                          beforeUpload={() => false}
                          fileList={videoList}
                          onChange={(info) => handleFileChange("videos", info)}
                        >

                          <Button
                            className="text-start"
                            style={{ width: "310px", height: "60px" }}
                            icon={<UploadOutlined />}
                          >
                            Dish video
                          </Button>
                          <span> (*Please select only one video)</span>
                        </Upload>
                      </div> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleCloseUpload}
                      >
                        Close
                      </button>
                      <button
                        onClick={handleUpload}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                      <ToastContainer />
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
      {isUpdatePublishModalOpen && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Update Publish combo offer
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdatePublishClose}
                ></button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <div className=" row">
                              <div className=" col-md-4 mb-3">
                                <label>
                                  Select Branch
                                  <span className="text-danger"> *</span>
                                </label>
                                <MultiSelect
                                  options={branchOptions}
                                  value={selected}
                                  onChange={handleBranchChange}
                                  labelledBy="Select Branch"
                                  className="scrollable-dropdown"
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>Start Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="startDate"
                                  value={formData.startDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter Start Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                              <div className=" col-md-4 mb-3">
                                <label>End Date & Time</label>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  name="endDate"
                                  value={formData.endDate}
                                  style={{ width: "200px" }}
                                  placeholder="Enter End Date & Time"
                                  onChange={handleDateChange}
                                />
                              </div>
                            </div>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => handleUpdateShow(dishId)}
                    >
                      <i className="bi bi-caret-left-fill"></i> Previous
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={submitUpdatePublish}
                    >
                      Save Changes
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default ComboOffer;
