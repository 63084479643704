import React, { useEffect, useState } from 'react';
import './ContactUs.css'; // Ensure this CSS is unique to this component

const ContactUs = () => {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log('Form submitted', formData);
  };

  return (
    <div className="contact-us">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <div className="contact-details">
        <a style={{ color: "black" }} href="https://wa.me/971507245494" target="_blank">
          +971 50724 5494
        </a>

          <p><b>Call us</b></p>
          <a style={{ color: "black" }} href="tel:+971507245494">
              +971 50724 5494
            </a>

          <p><b>Email us</b></p>
          <a style={{ color: "black" }} href="mailto:team@menudealz.com">
          team@menudealz.com
            </a>
        </div>
      </div>

      <div className="contact-form">
        <h3>Send us a message</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="How can we help you?"
              required
            />
          </div>
          <button type="submit" className="submit-btn">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
