import React, { useEffect, useRef, useState } from "react";
import "./restaurantMenu.css";
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../Loader";
import defaultImg from "./img/menu/no img.png";
import defaultcatImg from "./img/menu/menu-item-3.png";
import {
  Dish,
  RestaurantBranchMenuDetailInterface,
} from "./RestaurantBranchMenuDetailInterface";
import { message } from "antd";

// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}

export default function DefaultMenu({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [selectedCurrencyData, setSelectedCurrencyData] = useState<string>(
    data.selectedCurrency
  );
  const tabRefs = useRef({});
  const [showDropdown, setShowDropdown] = useState(false);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();
  const [loading, setLoading] = useState(true);

  // State to manage active tab
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  // Define categoryRefs with proper typing
  const categoryRefs = useRef<{
    [key: string]: React.RefObject<HTMLDivElement>;
  }>({});

  const navRef = useRef<HTMLUListElement>(null);
  const [dropdownStates, setDropdownStates] = useState({});

  const toggleDropdown = (categoryId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId], // Toggle the current category's dropdown
    }));
  };

  const closeDropdowns = () => {
    setDropdownStates({});
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(categoryRefs.current).every(
        (id) =>
          categoryRefs.current[id].current &&
          !categoryRefs.current[id].current.contains(event.target)
      );
      if (isOutside) {
        closeDropdowns();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Initialize categoryRefs with refs for each category
    if (restaurantBrantMenuDetails) {
      restaurantBrantMenuDetails.category.forEach((item) => {
        if (!categoryRefs.current[item._id]) {
          categoryRefs.current[item._id] = React.createRef();
        }
      });
    }
  }, [restaurantBrantMenuDetails]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (navRef.current) {
  //       if (window.scrollY > navRef.current.offsetTop) {
  //         navRef.current.classList.add("sticky");
  //       } else {
  //         navRef.current.classList.remove("sticky");
  //       }

  //       const sectionPositions = Object.values(categoryRefs.current).map(
  //         (ref) => ({
  //           id: ref.current?.id,
  //           offsetTop: ref.current?.offsetTop ?? 0,
  //         })
  //       );

  //       const scrollPosition = window.scrollY + 200; // Adjust this value based on your layout

  //       for (let i = sectionPositions.length - 1; i >= 0; i--) {
  //         if (scrollPosition >= sectionPositions[i].offsetTop) {
  //           const newActiveTab = sectionPositions[i].id;
  //           setActiveTab(newActiveTab);

  //           // Scroll the active tab into view
  //           tabRefs.current[newActiveTab]?.scrollIntoView({
  //             behavior: "smooth",
  //             inline: "center", // Centers the tab in the view
  //           });
  //           break;
  //         }
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const handleTabChange = (categoryId) => {
    setActiveTab(categoryId);

    const targetCategory = categoryRefs.current[categoryId]?.current;
    if (targetCategory) {
      const offset = 120; // adjust this value based on your layout or navbar height
      const targetPosition = targetCategory.offsetTop - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const viewDetails = (dish: Dish) => {
    console.log(JSON.stringify(dish));
    setShowViewBranchModal(true);
    setSelectedDish(dish);
  };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
        data.menuId +
        " branchId: " +
        data.branchId +
        " restaurantId: " +
        data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency);
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      const url = `/api/distributor/branch-menu/${data.menuId}?branchId=${data.branchId}&restaurantId=${data.restaurantId}&isPreview=true`;
      const response = await api.get(url);
      console.log("success menu details response", response.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response?.data?.message || "An error occurred.");
      setLoading(false);
    }
  };

  useEffect(() => {
    // Ensure that restaurantBrantMenuDetails has been updated before setting the active tab
    if (
      restaurantBrantMenuDetails &&
      restaurantBrantMenuDetails.category.length > 0
    ) {
      // handleTabChange(restaurantBrantMenuDetails.category[0]._id);
    }
  }, [restaurantBrantMenuDetails]);

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };

  return (
    <>
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
          {loading ? (
            <Loader />
          ) : (
            <>
              {/* <div className="section-header">
                <h2 className="menu_name">
                  {restaurantBrantMenuDetails?.name}
                </h2>
                <h2>
                  <span>{restaurantBrantMenuDetails?.description}</span>
                </h2>
              </div> */}

              <ul
                className="nav nav-tabs scroll-menu"
                // ref={navRef} // Add the reference here
                style={{ backgroundColor: "white" }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                {restaurantBrantMenuDetails?.category !== null &&
                  restaurantBrantMenuDetails?.category?.map((item, index) => (
                    <li key={index} className="nav-item catimg">
                      <a
                        className={`nav-link`}
                        ref={(el) => (tabRefs.current[item._id] = el)} // Assign ref here
                        onClick={() => handleTabChange(item._id)}
                      >
                        <img
                          src={item.imageAttachement[0]?.url || defaultcatImg}
                          alt={item.name}
                          style={{ width: "70px", height: "70px" }}
                        />
                        <p
                          style={{ textTransform: "capitalize" }}
                          className={`cat ${
                            activeTab === item._id ? "catactivetab" : ""
                          }`}
                        >
                          {item.name}
                        </p>
                      </a>
                    </li>
                  ))}
              </ul>

              {/* Display categories with their dishes */}
              {restaurantBrantMenuDetails?.category?.map((category, index) => (
                <div
                  key={index}
                  id={category._id}
                  ref={categoryRefs.current[category._id]}
                  className="category-section"
                >
                  {/* Category Heading */}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>
                      <span
                        style={{
                          textTransform: "capitalize",
                          display: "inline-block",
                        }}
                      >
                        {category.name}
                      </span>
                      <h5
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          color: "gray",
                        }}
                      >
                        ({category.dish ? category.dish.length : 0} items)
                      </h5>
                    </h4>

                    {/* Dropdown Trigger */}
                    <div style={{ position: "relative" }}>
                      <button
                        className="btn btn-light"
                        style={{ border: "none", background: "transparent" }}
                        onClick={() => toggleDropdown(category._id)}
                      >
                        <i
                          className="bi bi-sliders2-vertical"
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </button>

                      {/* Dropdown Menu */}
                      {dropdownStates[category._id] && (
                        <div
                          className="dropdown-menu"
                          style={{
                            display: "block",
                            position: "absolute",
                            right: 0,
                            top: "2.5rem",
                            zIndex: 100,
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            padding: "10px",
                            backgroundColor: "white",
                          }}
                        >
                          <div
                            className="dropdown-filter"
                            style={{ padding: "5px 10px" }}
                          >
                            <input
                              type="checkbox"
                              id={`lowToHigh-${category._id}`}
                              name="price"
                              value="lowToHigh"
                            />
                            <label
                              htmlFor={`lowToHigh-${category._id}`}
                              style={{ marginLeft: "8px" }}
                            >
                              Price: Low to High
                            </label>
                          </div>
                          <hr style={{ margin: "0rem 0" }} />
                          <div
                            className="dropdown-filter"
                            style={{ padding: "5px 10px" }}
                          >
                            <input
                              type="checkbox"
                              id={`highToLow-${category._id}`}
                              name="price"
                              value="highToLow"
                            />
                            <label
                              htmlFor={`highToLow-${category._id}`}
                              style={{ marginLeft: "8px" }}
                            >
                              Price: High to Low
                            </label>
                          </div>
                          <hr style={{ margin: "0rem 0" }} />
                          <div
                            className="dropdown-filter"
                            style={{
                              padding: "5px 10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input type="checkbox" id={`veg-${category._id}`} />
                            <label
                              htmlFor={`veg-${category._id}`}
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Veg
                              <div
                                className="veg-icon"
                                style={{ marginLeft: "8px" }}
                              >
                                <div className="circle"></div>
                              </div>
                            </label>
                          </div>
                          <hr style={{ margin: "0rem 0" }} />
                          <div
                            className="dropdown-filter"
                            style={{
                              padding: "5px 10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`nonVeg-${category._id}`}
                            />
                            <label
                              htmlFor={`nonVeg-${category._id}`}
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Non-Veg
                              <div
                                className="nonveg-icon"
                                style={{ marginLeft: "8px" }}
                              >
                                <div className="triangle"></div>
                              </div>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <p>{category.description}</p> */}

                  {/* Dishes under the category */}
                  <div className="row gy-4">
                    {category.dish?.map((dish, dishIndex) => (
                      <div
                        key={dishIndex}
                        className="col-lg-3 col-md-4 col-sm-6"
                      >
                        <div
                          className="custom-dish-card h-100"
                          onClick={() => viewDetails(dish)}
                        >
                          <div className="cursor-pointer">
                            <img
                              src={dish?.imageAttachement[0]?.url || defaultImg}
                              className="card-img"
                              alt={dish.name}
                            />
                            {/* <div className="offer-price-overlay">               
                            <span style={{ color: "black" }}>
                            {selectedCurrencyData} {dish.price}</span>
                          </div> */}
                          </div>
                          <div className="unique-dish-card-body">
                            <div
                              className="dish-veg-icon"
                              style={{ marginLeft: "8px" }}
                            >
                              <div className="circle"></div>
                            </div>
                            {/* Add Veg Icon */}
                            <p className="unique-dish-card-title">
                              {dish.name.length > 25
                                ? `${dish.name.substring(0, 25)}...`
                                : dish.name}{" "}
                            </p>
                            <p className="unique-dish-price">
                              {selectedCurrencyData} {dish.price}
                            </p>
                            <p className="unique-dish-details">
                              {dish.preparationTime}min • Good for{" "}
                              {dish.servingSize}
                            </p>
                            <p className="unique-dish-description">
                              {dish.description.length > 40
                                ? `${dish.description.substring(0, 40)}...`
                                : dish.description}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          {showViewBranchModal && (
            <div
              className="modal modal-lg"
              tabIndex={-1}
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Dish Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                      <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                          {selectedDish.imageAttachement
                            .concat(selectedDish.videoAttachement)
                            .map((media, index) => {
                              const isImage = media.url.match(
                                /\.(jpeg|jpg|gif|png|jfif)$/i
                              );
                              const isVideo =
                                media.url.match(/\.(mp4|webm|ogg)$/i);

                              return (
                                <div
                                  key={media._id}
                                  className={`carousel-item ${
                                    index === 0 ? "active" : ""
                                  }`}
                                >
                                  {isImage && (
                                    <img
                                      src={media.url}
                                      className="d-block w-100"
                                      alt={`Slide ${index}`}
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {isVideo && (
                                    <video
                                      className="d-block w-100"
                                      width={250}
                                      height={310}
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source
                                        src={media.url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <br />
                        {/* Show navigation buttons only if there's more than one media item */}
                        {selectedDish.imageAttachement.length +
                          selectedDish.videoAttachement.length >
                          1 && (
                          <>
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExample"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                style={{ backgroundColor: "black" }}
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExample"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                style={{ backgroundColor: "black" }}
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </>
                        )}
                      </div>

                      <table className="table table-bordered dish-view-table custom-width-Dishtable">
                        <tbody>
                          <tr>
                            <th style={{borderColor:"#aaaaaa"}}>Dish Name</th>
                            <td style={{borderColor:"#aaaaaa"}}>{selectedDish.name}</td>
                          </tr>
                          <tr>
                            <th style={{borderColor:"#aaaaaa"}}>Dish Details </th>
                            <td style={{borderColor:"#aaaaaa"}}>{selectedDish.description}</td>
                          </tr>
                          <tr>
                            <th style={{borderColor:"#aaaaaa"}}>Price</th>
                            <td style={{borderColor:"#aaaaaa"}}>
                              {selectedCurrencyData} {selectedDish.price}
                            </td>
                          </tr>
                          <tr>
                            <th style={{borderColor:"#aaaaaa"}}>Ingredient</th>
                            <td style={{borderColor:"#aaaaaa"}}>
                              {selectedDish.ingrediants
                                .map((ingredient) => ingredient.name)
                                .join(", ")}
                            </td>
                          </tr>
                          <tr>
                            <th style={{borderColor:"#aaaaaa"}}>Preparation Time(Minutes)</th>
                            <td style={{borderColor:"#aaaaaa"}}>{selectedDish.preparationTime}</td>
                          </tr>
                          <tr>
                            <th style={{borderColor:"#aaaaaa", background:"white"}}>Good For</th>
                            <td style={{borderColor:"#aaaaaa"}}>{selectedDish.servingSize} Person</td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="text-danger">
                        * Please inform the staff if you are allergic to any
                        ingredients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
