import { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import { message } from "antd";
interface ChildComponentProps {
  handleRegisterClose: () => void;
  SetMenuID: (id: string) => void;
}
const DishRegister = (props: ChildComponentProps) => {
  const { handleRegisterClose, SetMenuID } = props;
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "",
    productionCost: 0,
    preparationTime: 0,
    servingSize: 0,
    categoryTag: [""], // Initialize categoryTag as an empty array
    ingrediants: [
      {
        name: "",
        description: "",
        quantity: 0,
        unit: "",
      },
    ],
  });

  // console.log("formData", formData);

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]:
  //       name === "productionCost" ||
  //       name === "preparationTime" ||
  //       name === "servingSize"
  //         ? parseInt(value)
  //         : value,
  //   });
  // };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Allow any input for name and description
    const allowAnyInput = /[^]+/;

    if (
      name === "name" ||
      name === "description" ||
      allowAnyInput.test(value) ||
      value === ""
    ) {
      setFormData({
        ...formData,
        [name]:
          name === "productionCost" ||
            name === "preparationTime" ||
            name === "servingSize"
            ? parseFloat(value) || 0 // Set value to 0 if it's NaN
            : value,
      });
    } else {
      // Handle the case when the input is not a valid input for name or description
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };

  // const handleIngredientChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  //   index: number
  // ) => {
  //   const { name, value } = e.target;

  //   // Create a deep copy of the ingredients array
  //   const updatedIngredients = formData.ingrediants.map((ingredient, i) => {
  //     // Update the specific ingredient at the given index
  //     if (i === index) {
  //       return {
  //         ...ingredient,
  //         [name]: name === "quantity" ? parseInt(value) : value,
  //       };
  //     }
  //     return ingredient;
  //   });

  //   setFormData({
  //     ...formData,
  //     ingrediants: updatedIngredients,
  //   });
  // };
  //////////////////////////////////
  // const handleIngredientChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  //   index: number
  // ) => {
  //   const { name, value } = e.target;

  //   const updatedIngredients = formData.ingrediants.map((ingredient, i) => {
  //     if (i === index) {
  //       return {
  //         ...ingredient,
  //         [name]: name === "quantity" ? parseInt(value) : value,
  //       };
  //     }
  //     return ingredient;
  //   });

  //   setFormData({
  //     ...formData,
  //     ingrediants: updatedIngredients,
  //   });
  // };

  const handleIngredientChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedIngredients = formData.ingrediants.map((ingredient, i) => {
      if (i === index) {
        return {
          ...ingredient,
          [name]: name === "quantity" ? parseFloat(value) : value,
        };
      }
      return ingredient;
    });

    setFormData({
      ...formData,
      ingrediants: updatedIngredients,
    });
  };

  const handleAddIngredient = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ingrediants: [
        ...prevFormData.ingrediants,
        { name: "", description: "", quantity: 0, unit: "" },
      ],
    }));
  };


  const handleRemoveIngredient = () => {

    setFormData((prevFormData) => ({
      ...prevFormData,
      ingrediants: prevFormData.ingrediants.slice(0, -1),
    }));

  };

  const categoryTagChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ): void => {
    const { value } = e.target;

    const updatedCategoryTag = formData.categoryTag.map((tag, i) =>
      i === index ? value : tag
    );

    setFormData({
      ...formData,
      categoryTag: updatedCategoryTag,
    });
  };

  const handleAddCategoryTag = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryTag: [...prevFormData.categoryTag, ""],
    }));
  };

  const handleRemoveTag = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryTag: prevFormData.categoryTag.slice(0, -1),
    }));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }
    const postData = {
      name: formData.name,
      description: formData.description,
      type:formData.type,
      productionCost: formData.productionCost,
      preparationTime: formData.preparationTime,
      servingSize: formData.servingSize,
      categoryTag: formData.categoryTag, // Assuming categoryTag is already an array
      ingrediants: formData.ingrediants.map((ingredient) => ({
        name: ingredient.name,
        description: ingredient.description,
        quantity: ingredient.quantity,
        unit: (null == ingredient.unit || '' === ingredient.unit) ? 'mg' : ingredient.unit,
      })),
    };

    try {
      const response = await api.post("/api/restaurant/dish/", postData);

      // console.log("this dish response", response);
      setFormData({
        name: "",
        type:"",
        description: "",
        productionCost: 0,
        preparationTime: 0,
        servingSize: 0,
        categoryTag: [""],
        ingrediants: [
          {
            name: "",
            description: "",
            quantity: 0,
            unit: "",
          },
        ],
      });

      toast.success(response.data.message);
      message.success(response.data.message);
      console.log("Dish addes successfully");
      // setResponse(true);
      handleRegisterClose();
      SetMenuID(response.data.data._id)
      
    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        toast.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        toast.error("Please fill out all required fields.");
      }
    }
  };
  const [errors, setErrors] = useState<{ name?: string; ingrediants?: string[] }>({});
  const validate = (): boolean => {
    const newErrors: { name?: string; ingrediants?: string[] } = {};
  
    // Check if dish name is provided
    if (!formData.name.trim()) {
      newErrors.name = "Dish Name is required.";
    }
  
    // Check if all ingredient names are provided
    const ingrediantErrors = formData.ingrediants.map((ingredient) => {
      if (!ingredient.name.trim()) {
        return "Ingredient Name is required.";
      }
      return "";
    });
  
    // If there are errors in ingredients, add them to the newErrors object
    if (ingrediantErrors.some((error) => error !== "")) {
      newErrors.ingrediants = ingrediantErrors;
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      const parsedData = JSON.parse(loggedInUserData);
      setDefaultCurrency(parsedData.restaurantdefaultCurrencyCode);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="table-responsive">
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  Dish Name<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  placeholder="Enter Dish Name"
                  onChange={handleInputChange}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Average Preparation Time (in minutes)
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="preparationTime"
                  // value={formData.preparationTime}
                  placeholder="Enter Average Preparation Time (in minutes)"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col">
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  {/* Base Production Cost(INR) */}
                  Base Production Cost ({defaultCurrency})
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="productionCost"
                  // value={formData.productionCost}
                  placeholder="Enter Base Production Cost"
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Number of servings
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="servingSize"
                  // value={formData.servingSize}
                  placeholder="Enter Number of servings"
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="mb-3">
                      <label className="form-label">
                        {" "}
                        It would be great if you add a description about the dish{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        rows={5}
                      ></textarea>
                    </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col">
            <div className="mb-3">
                  <label className="form-label me-3">
                    Cuisine Type<span className="text-danger"> *</span>
                  </label>
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type" // Match this with the formData field
                    id="veg"
                    value="veg" // Lowercase to match the expected payload format
                    checked={formData.type === 'veg'}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="veg">
                    Veg
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type" // Match this with the formData field
                    id="nonVeg"
                    value="non-veg" // Lowercase to match the expected payload format
                    checked={formData.type === 'non-veg'}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="nonVeg">
                    Non-Veg
                  </label>
                </div>
                  {/* {errors.cuisineType && (
                    <p className="text-danger">{errors.cuisineType}</p>
                  )} */}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  It would be great if you add a description about the dish{" "}
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={5}
                  placeholder="Enter description about the dish"
                  maxLength={200}
                ></textarea>
              </div>
              


            </div>
          </div>
          <h5>Ingredients</h5>
          <div className="row">
            <div className="col">
              <table className="table table-bordered table-responsive">
                <tbody>
                  {formData.ingrediants.map((ingredient, index) => (
                    <tr key={index}>
                      <td>
                        <label>Ingredient Name<span className="text-danger"> *</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ingredient Name"
                          name="name"
                          value={ingredient.name}
                          onChange={(e) => handleIngredientChange(e, index)}
                        />
                        {errors.ingrediants && errors.ingrediants[index] && (
                        <p className="text-danger">{errors.ingrediants[index]}</p>
                      )}
                      </td>
                      <td>
                        <label>Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Quantity"
                          name={`quantity`}
                          //min={0}
                          //value={ingredient.quantity}
                          onChange={(e) => handleIngredientChange(e, index)}
                        />
                      </td>

                      <td>
                        <div className="mb-3">
                          <label>Unit</label>
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="unit"
                            name={`unit`}
                            value={ingredient.unit}
                            onChange={(e) => handleIngredientChange(e, index)}
                          /> */}

                          <select
                            className="form-control down-arrow"
                            name={`unit`}
                            id={`unit`}
                            value={ingredient.unit}
                            onChange={(e) => {
                              handleIngredientChange(e, index);
                              // console.log("unit", e.target.value);
                            }}
                          >
                            <option value="mg">Milligram</option>
                            <option value="ml">Millilitre</option>
                            <option value="nos">Numbers</option>
                            {/* <option value="audi">Number/Millilitre</option>
                            <option value="audi">Number/Millilitre</option> */}
                          </select>
                        </div>
                      </td>
                      <td>
                        <label>Description</label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="description"
                          name={`description`}
                          value={ingredient.description}
                          onChange={(e) => handleIngredientChange(e, index)}
                        />
                      </td>
                      <td>
                        {index === 0 ? (
                          <div></div>) : ( 
                          <a
                            href="#"
                            className="btn btn-primary rounded-circle act-btn"
                            style={{ background: "red", borderColor: "red" }}
                            onClick={handleRemoveIngredient}
                          >
                            <i className="bi bi-file-minus"></i>
                          </a>
                        )} 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-1">
              <a
                href="#"
                className="btn btn-primary rounded-circle act-btn"
                onClick={handleAddIngredient}
              >
                <i className="bi bi-file-plus"></i>
              </a>
            </div>
          </div>
          <h5>Dish Tagging(Ex.Indian, Delicious, Healthy etc.)</h5>
          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <tbody>
                  {/* {renderInputs()} */}
                  {formData.categoryTag.map((categoryTag, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Tag Name"
                          name={`categoryTag`}
                          value={categoryTag}
                          onChange={(e) => categoryTagChange(e, index)}
                        />
                      </td>
                      <td>
                        {index === 0 ? (
                          <div></div>) : (
                          <a
                            href="#"
                            className="btn btn-primary rounded-circle act-btn"
                            style={{ background: "red", borderColor: "red" }}
                            onClick={handleRemoveTag}
                          >
                            <i className="bi bi-file-minus"></i>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-1">
              <a
                href="#"
                className="btn btn-primary rounded-circle act-btn "
                onClick={handleAddCategoryTag}
              >
                <i className="bi bi-file-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleRegisterClose}
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
          Save & Next <i className="bi bi-caret-right-fill"></i>
          </button>
          <ToastContainer />
        </div>
      </form>
      ;
    </>
  );
};

export default DishRegister;
