import React, { useEffect, useState } from "react";
import RestaurantMenu from "./restaurantMenu/DefaultMenus";
// import { useParams } from "react-router-dom";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import {
  RestaurantDetailInterface,
  branchDetailInterface,
} from "../restaurant-list/interFace";
import { constants } from "buffer";
import BuffetMenu from "./restaurantMenu/BuffetMenu";
import DefaultMenu from "./restaurantMenu/DefaultMenus";
import EventMenu from "./restaurantMenu/EventMenu";
import TodaySpecial from "./restaurantMenu/TodaySpecial";
import ComboOffer from "./restaurantMenu/ComboOffer";
import DiscountedOffer from "./restaurantMenu/DiscountedOffer";
import { menuInfo } from "./restaurantMenu/RestaurantBranchMenuDetailInterface";
import heroImg1 from "./img/gallery-1.jpg";
import heroImg2 from "./img/gallery-2.jpg";
import heroImg3 from "./img/gallery-3.jpg";

export default function TempRestaurantView() {
  // const { menuId, branchId, restaurantId } = useParams<{
  //   menuId?: string;
  //   branchId?: string;
  //   restaurantId?: string;
  // }>();

  const [menuId, setMenuId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [status, setStatus] = useState(false);
  const [defaultMenus, setDefaultMenus] = useState([]);
  const [buffetMenus, setBuffetMenus] = useState([]);
  const [eventMenus, setEventMenus] = useState([]);
  const [todaySpecialMenus, setTodaySpecialMenus] = useState([]);
  const [comboOffer, setComboOffer] = useState([]);
  const [discountedOffer, setDiscountedOffer] = useState([]);
  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);

  const [restaurantDetail, setRestaurantDetail] = useState<menuInfo>();
  const [branchDetail, setBranchDetail] = useState<branchDetailInterface>();

  useEffect(() => {
    // console.log("menuId: " + menuId + " branchId: " + branchId + " restaurantId: " + restaurantId);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const a = params.get("a");
    const b = params.get("b");
    const c = params.get("c");
    const d = params.get("d");
    setMenuId(a);
    setBranchId(b);
    setRestaurantId(c);
    setSelectedMenuType(d);
  }, []);
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (restaurantId && branchId && menuId) {
      // fetchRestaurantDetailData();
      fetchBranchDetailData();
      getBranchAllDetails();
    }
  }, [restaurantId, branchId, menuId]);
  const getBranchAllDetails = async () => {
    let menuIdBlank = "{menuId}";
    try {
      await api
        .get(
          "/api/distributor/branch-menu/" +
            menuIdBlank +
            "?branchId=" +
            branchId +
            "&restaurantId=" +
            restaurantId +
            "&isPreview=true"
        )
        .then(function (response) {
          const defaultMenuIds = response.data.data.defaultMenus.map(
            (menu) => menu._id
          );
          const buffetMenuIds = response.data.data.buffetMenus.map(
            (menu) => menu._id
          );
          const eventMenuIds = response.data.data.eventMenus.map(
            (menu) => menu._id
          );
          const todaySpecialMenuIds = response.data.data.todaySpecials.map(
            (menu) => menu._id
          );
          const ComboOfferIds = response.data.data.comboSpecialOffers.map(
            (menu) => menu._id
          );
          const discountedOfferIds =
            response.data.data.discountedSpecialOffers.map((menu) => menu._id);

          setRestaurantDetail(response.data.data);
          setSelectedCurrencyData(
            response.data.data.restaurant.defaultCurrency.code
          );
          setStatus(response.data.data.restaurant.isAppisApproved);
          setBranchDetail(response.data.data.branch);
          setDefaultMenus(defaultMenuIds);
          setBuffetMenus(buffetMenuIds);
          setEventMenus(eventMenuIds);
          setTodaySpecialMenus(todaySpecialMenuIds);
          setComboOffer(ComboOfferIds);
          setDiscountedOffer(discountedOfferIds);

          const allMenus = {
            "Flash Dealz": discountedOfferIds,
            "Combo Dealz": ComboOfferIds,
            "Today Special": todaySpecialMenuIds,
            "Regular Menu": defaultMenuIds,
            Buffet: buffetMenuIds,
            "Event Special": eventMenuIds,
          };

          // Only find the first non-empty menu if `d` is null
          if (!selectedMenuType) {
            const firstNonEmptyMenu = Object.keys(allMenus).find(
              (selectedMenuType) =>
                allMenus[selectedMenuType] &&
                allMenus[selectedMenuType].length > 0
            );

            if (firstNonEmptyMenu) {
              setSelectedMenuType(firstNonEmptyMenu);
            } else {
              setSelectedMenuType("Regular Menu"); // Default fallback
            }
          }
        })
        .catch(function (error) {
          console.log("Show error notification!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const a = params.get("a");
    const b = params.get("b");
    const c = params.get("c");
    const d = params.get("d");

    setMenuId(a);
    setBranchId(b);
    setRestaurantId(c);

    if (d) {
      // If `d` is not null, set it directly as the selected menu type
      setSelectedMenuType(d);
      // } else {
      //     // If `d` is null, trigger `getBranchAllDetails` to run the menu selection logic
      //     getBranchAllDetails();
    }
  }, []);

  const fetchBranchDetailData = async () => {
    try {
      await api
        .get(
          "/api/identity/restaurant-branch/" +
            branchId +
            "?restaurantId=" +
            restaurantId
        )
        .then(function (response) {
          console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");
          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // const fetchRestaurantDetailData = async () => {
  //   try {
  //     if ("" !== restaurantId && null !== restaurantId) {
  //       await api
  //         .get(`/api/identity/restaurant/${restaurantId}`)
  //         .then(function (response) {
  //           const restaurantDetailData = response.data.data;
  //           console.log("*******1"+ response.data.data);
  //           console.log(response.data.data.isApproved);
  //           setStatus(response.data.data.isApproved);
  //           console.log("isApproved", response.data.data.isApproved);
  //           localStorage.setItem(
  //             "currencyDetails",
  //             restaurantDetailData.defaultCurrency.symbol
  //           );
  //           setSelectedCurrencyData(restaurantDetailData.defaultCurrency.code)
  //           console.log("defaultCurrency", restaurantDetailData.defaultCurrency.code);

  //           setRestaurantDetail(restaurantDetailData);
  //           return response.data;
  //         })
  //         .catch(function (error) {
  //           console.log("Show error notification!");
  //           toast.error("Error in api call!");
  //           console.log(error);
  //           return Promise.reject(error);
  //         });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching restaurant detail data:", error);
  //   }
  // };
  useEffect(() => {
    const textWatermark = "Preview";
    let fullTextWatermark = "";
    const n = 10000;
    for (let i = 0; i < n; i++) {
      fullTextWatermark += " " + textWatermark;
    }
    document.getElementById("watermark").innerHTML = fullTextWatermark;
  }, []);
  const menuTypes = [
    { type: "Flash Dealz", data: discountedOffer },
    { type: "Combo Dealz", data: comboOffer },
    { type: "Today Special", data: todaySpecialMenus },
    { type: "Regular Menu", data: defaultMenus },
    { type: "Buffet", data: buffetMenus },
    { type: "Event Special", data: eventMenus },
  ];
  const [selectedMenuType, setSelectedMenuType] = useState("");
  const renderMenus = () => {
    switch (selectedMenuType) {
      case "Regular Menu":
        return defaultMenus.length > 0 ? (
          defaultMenus.map((menuId) => (
            <DefaultMenu
              key={menuId}
              data={{
                menuId: menuId,
                branchId: branchId,
                restaurantId: restaurantId,
                selectedCurrency: selectedCurrencyData,
              }}
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          ></div>
        );
      case "Buffet":
        return (
          <div className="row">
            {buffetMenus.length > 0 ? (
              buffetMenus.map((menuId) => (
                <div className="col-sm-6 col-md-6 col-lg-3" key={menuId}>
                  <BuffetMenu
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency: selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              ></div>
            )}
          </div>
        );
      case "Event Special":
        return eventMenus.length > 0 ? (
          eventMenus.map((menuId) => (
            <div className="row justify-content-center">
              <div className="col-md-8" key={menuId}>
                <EventMenu
                  data={{
                    menuId: menuId,
                    branchId: branchId,
                    restaurantId: restaurantId,
                    selectedCurrency: selectedCurrencyData,
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div
            className="col-12 d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          ></div>
        );
      case "Today Special":
        return (
          <div className="row">
            {todaySpecialMenus.length > 0 ? (
              todaySpecialMenus.map((menuId) => (
                <div
                  className="col-sm-6 col-md-4 col-lg-3"
                  style={{ padding: "0px" }}
                  key={menuId}
                >
                  <TodaySpecial
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency: selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              ></div>
            )}
          </div>
        );
      case "Combo Dealz":
        return (
          <div className="row">
            {comboOffer.length > 0 ? (
              comboOffer.map((menuId) => (
                <div
                  className="col-sm-6 col-md-4 col-lg-3"
                  style={{ padding: "0px" }}
                  key={menuId}
                >
                  <ComboOffer
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency: selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              ></div>
            )}
          </div>
        );
      case "Flash Dealz":
        return (
          <div className="row">
            {discountedOffer.length > 0 ? (
              discountedOffer.map((menuId) => (
                <div
                  className="col-sm-6 col-md-4 col-lg-3"
                  style={{ padding: "0px" }}
                  key={menuId}
                >
                  <DiscountedOffer
                    data={{
                      menuId: menuId,
                      branchId: branchId,
                      restaurantId: restaurantId,
                      selectedCurrency: selectedCurrencyData,
                    }}
                  />
                </div>
              ))
            ) : (
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ height: "200px" }}
              ></div>
            )}
          </div>
        );

      default:
        return null;
    }
  };
  const [activeTab, setActiveTab] = useState(menuTypes[0]?.type);

  return (
    <div className="container" style={{ fontFamily: "'Mulish', sans-serif" }}>
      {!status && <p id="watermark"></p>}
      <div className="row">
        <div className="col-md-12">
          <section
            id="hero"
            className="hero hero-section"
            style={{ background: "white", padding: "50px 0 0 0", minHeight: "0px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col" style={{ padding: "15px 2px 0px 0px" }}>
                  <img
                    src={heroImg1} 
                    alt="Interior Design"
                    className="img-fluid shadow hero-img"
                  />
                </div>
                <div className="col" style={{ padding: "15px 2px 0px 0px" }}>
                  <img
                    src={heroImg2} 
                    alt="Delicious Food"
                    className="img-fluid shadow hero-img"
                  />
                </div>
                <div className="col" style={{ padding: "15px 2px 0px 0px" }}>
                  <img
                    src={heroImg3} 
                    alt="Dining Room"
                    className="img-fluid shadow hero-img"
                  />
                </div>
                {/* Center Logo */}
                <div className="text-center mb-4" data-aos="zoom-in">
                  <img
                    src={
                      restaurantDetail?.restaurant?.logoUrl ||
                      "logoPlaceholder.png"
                    } 
                    alt="Restaurant Logo"
                    className="rounded-circle border shadow hero-logo"
                  />
                </div>
              </div>

              {/* Title and Subtitle */}
              <h2
                className="text-center mb-3 restaurant-name"
                style={{ color: "#1E1E1E", fontSize: "24px" }}
              >
                {branchDetail?.name}
              </h2>
              <h4
                className="text-center mb-3 branch-name"
                style={{ color: "green", fontSize: "1.8rem" }}
              >
                {branchDetail?.tagline}
              </h4>
              <h5
                className="text-center text-muted cuisine-tag"
                style={{ fontSize: "1.2rem", color: "666666" }}
              >
                {restaurantDetail?.restaurant?.cuisineTag?.length > 0
                  ? restaurantDetail?.restaurant?.cuisineTag?.join(" | ")
                  : ""}
              </h5>
            </div>
          </section>

          <br />

          <div className="offers-tabs">
              
          <i className="bi bi-search" style={{ fontSize: 'xx-large' }}></i>

            {menuTypes
              .filter((menu) => menu.data.length > 0)
              .map((menu) => (
                <button
                  key={menu.type}
                  className={`tab-button ${
                    selectedMenuType === menu.type ? "active" : ""
                  } `}
                  onClick={() => setSelectedMenuType(menu.type)}
                >
                  {menu.type}
                </button>
              ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">{renderMenus()}</div>
      </div>
      <ToastContainer />
    </div>
  );
}
