import React, { useEffect, useState } from "react";
// import { RestaurantSection } from "../../../constants/constant";
// Using ESM specification
import "./restaurantMenu.css";
// Using a bundler like webpack
import GLightbox from "glightbox";
import { toast } from "react-toastify";
import api from "../../../api";
import defaultImg from "./img/menu/no img.png";
import Loader from "../../Loader";
// import { useParams } from "react-router-dom";
import { message } from "antd";
// Define the type for props
interface RestaurantMenuProps {
  data: {
    menuId: string;
    branchId: string;
    restaurantId: string;
    selectedCurrency: string;
  };
}
interface RestaurantBranchMenuDetailInterface {
  _id: string;
  name: string;
  isActive: boolean;
  description: string;
  totalPrice: number;
  offerPrice: number;
  dish: [Dish];
  imageAttachement: any[];
}
interface Dish {
  _id: string;
  name: string;
  description: string;
  price: number;
}
export default function ComboOffer({ data }: RestaurantMenuProps) {
  const [showViewBranchModal, setShowViewBranchModal] = useState(false);

  const [selectedCurrencyData, setSelectedCurrencyData] = useState(String);
  const [loading, setLoading] = useState(true);

  const [restaurantBrantMenuDetails, setRestaurantBrantMenuDetails] =
    useState<RestaurantBranchMenuDetailInterface>();

  // State to manage active tab
  // const [activeTab, setActiveTab] = useState(restaurantBrantMenuDetails?.category[0]._id);

  // Function to handle tab change
  // const handleTabChange = (categoryId: string) => {
  //   setActiveTab(categoryId);
  // };

  useEffect(() => {
    console.log(
      "inside RestaurantMenu component menuId: " +
        data.menuId +
        " branchId: " +
        data.branchId +
        " restaurantId: " +
        data.restaurantId
    );
    setSelectedCurrencyData(data.selectedCurrency)
    if (data.menuId) {
      getRestaurantBranchMenuDetails();
    }
  }, [data.menuId, data.branchId, data.restaurantId]);

  const getRestaurantBranchMenuDetails = async () => {
    setLoading(true);
    try {
      var url =
        "/api/distributor/branch-menu/" +
        data.menuId +
        "?branchId=" +
        data.branchId +
        "&restaurantId=" +
        data.restaurantId +
        "&isPreview=true";
      const response = await api.get(url);
      console.log("success menu details response", response.data.data);
      setRestaurantBrantMenuDetails(response.data.data);
      setLoading(false);
      // let currencyData = localStorage.getItem("currencyDetails");
      // setSelectedCurrencyData(currencyData);
      // console.log("currencyData" + currencyData);
    } catch (error) {
      console.error("Error fetching dish data:", error);
      message.error(error.response.data.message);
    }
  };
  

  useEffect(() => {
    // Initialize GLightbox when the component mounts
    const lightbox = GLightbox({
      selector: ".glightbox", // selector for lightbox elements
    });

    // Optionally, you can clean up the lightbox when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []); // empty dependency array ensures the effect runs only once

  const handleViewBranchClose = () => {
    setShowViewBranchModal(false);
  };
  const viewDetails = () => {
    setShowViewBranchModal(true);
  };

  return (
    <>
      {/* ======= Menu Section ======= */}
      <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
        {loading ? (
            <Loader />
          ) : (
            <>
            <div className="row gy-4">
          
          <div>
            <div className="custom-dish-card h-100" style={{ height: '400px' }} onClick={() => viewDetails()}>
              <div className="cursor-pointer" >
                <img 
                  src={
                    restaurantBrantMenuDetails?.imageAttachement?.[0]?.url ||
                    defaultImg
                  }
                  className="card-img"
                  alt={restaurantBrantMenuDetails?.name || "Default Alt Text"}
                   
                />
                <div className="offer-price-overlay">
                {restaurantBrantMenuDetails?.totalPrice > 0 && 
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#FF0000",
                      marginRight: "8px",
                    }}
                  >
                    {selectedCurrencyData}{" "}
                    {restaurantBrantMenuDetails?.totalPrice}
                  </span>
                }

                {restaurantBrantMenuDetails?.offerPrice > 0 && <span style={{ color: "black" }}>{selectedCurrencyData}{" "}
                {restaurantBrantMenuDetails?.offerPrice ?? 0}</span>}
                
                </div>
              </div>
              <div className="custom-dish-card-body">
              <h5 className="custom-dish-card-title dish-name cursor-pointer">
                {restaurantBrantMenuDetails?.name.length > 27 
                  ? restaurantBrantMenuDetails?.name?.substring(0, 23) + "..." 
                  : restaurantBrantMenuDetails?.name}
              </h5>

                
      
              <p className="custom-dish-card-text text-muted">
  {restaurantBrantMenuDetails?.description
    ? (
      restaurantBrantMenuDetails.description.length > 23
        ? `${restaurantBrantMenuDetails.description.substring(0, 23)}...`
        : restaurantBrantMenuDetails.description
      )
    : <><br /></>}
</p>


                
                
              </div>
            </div>
          </div>
       
            </div>
            </>
          
          
          
          )}
          {showViewBranchModal && (
            <div
              className="modal modal-lg"
              tabIndex={-1}
              style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Combo Dealz Details
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleViewBranchClose}
                    ></button>
                  </div>
                  <div className="delete-text">
                    <div className="mb-12 col-md-12">
                    <table className="table table-bordered view-table infoTable">
                        <tbody>
                          <tr>
                            <th>Dish Name</th>
                            <td>{restaurantBrantMenuDetails?.name}</td>
                            <th>Description</th>
                            <td>{restaurantBrantMenuDetails?.description}</td>
                          </tr>
                          <tr>
                            <th>Original Price</th>
                            <td>{selectedCurrencyData} {restaurantBrantMenuDetails?.totalPrice}</td>
                            <th>Offer Price</th>
                            <td>{selectedCurrencyData} {restaurantBrantMenuDetails?.offerPrice}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-active">
                          
                          <th scope="col">DISH NAME</th>

                          <th scope="col">DESCRIPTION</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {restaurantBrantMenuDetails.dish.map((item, index) => (
                          <tr key={item._id}>
                            
                            <td>{item.name}</td>
                            
                            <td>{item.description}</td>
                          </tr>
                        ))}


                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
