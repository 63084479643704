import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./footer.css";
// import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import api from "../../api";
import { BranchDetailsInterface } from "./BranchDetailsInterface";
export default function Footer() {
  const { pathname } = useLocation();
  const [isShowWorkingHours, setIsShowWorkingHours] = useState(false);
  console.log("path is " + pathname);

  const [branchDetails, setBranchDetails] = useState<BranchDetailsInterface>();

  const fetchBranchDetailData = async (
    restaurantId: string,
    branchId: string
  ) => {
    try {
      await api
        .get(
          "/api/identity/restaurant-branch/" +
            branchId +
            "?restaurantId=" +
            restaurantId
        )
        .then(function (response) {
          console.log("sucess respp get branch details");
          console.log(response.data);

          setBranchDetails(response.data.data);

          return response.data;
        })
        .catch(function (error) {
          console.log("Show error notification!");

          console.log(error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    if (pathname.startsWith("/restaurant")) {
      setIsShowWorkingHours(true);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      // const menuId = params.get('a');
      const branchId = params.get("b");
      const restaurantId = params.get("c");
      if (restaurantId) {
        fetchBranchDetailData(restaurantId, branchId);
      }
    } else {
      setIsShowWorkingHours(false);
    }
  }, [pathname]);

  // you can check a more conditions here
  if (
    pathname === "/sidebar" ||
    pathname === "/menu" ||
    pathname === "/dish" ||
    pathname === "/restaurants" ||
    pathname === "/profile" ||
    pathname === "/theme" ||
    pathname === "/admin-panel" ||
    pathname === "/restaurant-theme" ||
    pathname === "/category" ||
    pathname === "/branch-menu" ||
    pathname === "/branches" ||
    pathname === "/menu-creation" ||
    pathname === "/menu-view" ||
    pathname === "/restaurants-info" ||
    pathname === "/document-upload" ||
    pathname === "/menu-creation" ||
    pathname === "/today-combo-offers" ||
    pathname === "/today-discounted-offers" ||
    pathname === "/upcoming-offers" ||
    pathname === "/restaurant-special" ||
    pathname === "/todays-special" ||
    pathname === "/event-meal" ||
    pathname === "/buffet-meal" ||
    pathname === "/users" ||
    // pathname === "/home-page" ||
    pathname === "/subscription-plan" ||
    pathname === "/subscription-info" ||
    pathname === "/change-password" ||
    pathname.startsWith("/menu-creation") ||
    pathname.startsWith("/buffet-menu-creation") ||
    pathname.startsWith("/menu-view") ||
    pathname.startsWith("/buffet-menu-view")
  )
    return null;

  return (
    <div
      style={{ fontFamily: "'Mulish', sans-serif" }}
      className="footer-container"
    >
      {pathname.startsWith("/restaurant") ? (
        <footer id="footer" className="footer">
          <div className="container footer-line">
            <div className="container">
              <div className="row" style={{ padding: "0px" }}>
                <hr className="footer-divider d-lg-none" />
                <div className="col-lg-5 col-md-5 d-flex cef">
                  {isShowWorkingHours ? (
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          marginBottom: "10px",
                        }}
                      >
                        OPENING HOURS
                      </h4>
                      <div>
                        {branchDetails?.workingHour !== null &&
                          Array.isArray(branchDetails?.workingHour) &&
                          (branchDetails?.workingHour as any[]).map(
                            (item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "1.1rem",
                                  lineHeight: "1.5",
                                  color: "#333",
                                }}
                              >
                                {/* Day */}
                                <div style={{ flex: "1" }}>
                                  {item.day === 1
                                    ? "Monday"
                                    : item.day === 2
                                    ? "Tuesday"
                                    : item.day === 3
                                    ? "Wednesday"
                                    : item.day === 4
                                    ? "Thursday"
                                    : item.day === 5
                                    ? "Friday"
                                    : item.day === 6
                                    ? "Saturday"
                                    : item.day === 7
                                    ? "Sunday"
                                    : ""}
                                </div>

                                {/* Colon */}
                                <div style={{ marginRight: "10px" }}>:</div>

                                {/* Time */}
                                <div style={{ flex: "2", textAlign: "left" }}>
                                  {item.startTime} AM - {item.endTime} PM
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <hr className="footer-divider d-lg-none" />
                <div className="col-lg-4 col-md-4 d-flex cef">
                  <div>
                    <h4>Contact Us</h4>
                    <p>
                      {branchDetails && branchDetails.contactNumber && (
                        <a
                          href={`tel:${branchDetails.contactNumber}`}
                          className="a-tag"
                          style={{ fontSize: "large" }}
                        >
                          <i className="bi bi-telephone icon" />{" "}
                          {branchDetails.contactNumber}
                        </a>
                      )}
                    </p>
                    <p>
                      {branchDetails && branchDetails.email && (
                        <a
                          href={`mailto:${branchDetails.email || ""}`}
                          className="a-tag"
                          style={{ fontSize: "large" }}
                        >
                          <i className="bi bi-envelope-at icon" />{" "}
                          {branchDetails.email || "N/A"}
                        </a>
                      )}
                    </p>
                    <p>
                      {branchDetails && branchDetails.address && (
                        <a style={{ fontSize: "large", color: "#4c4c4c" }}>
                          <i className="bi bi-geo-alt-fill icon" />{" "}
                          {branchDetails.address || "N/A"}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
                <hr className="footer-divider d-lg-none" />

                <div className="col-lg-3 col-md-3 d-flex cef">
                  <div className="cef">
                    <h4>FOLLOW US</h4>
                    <div className="social-links d-flex">
                      {/* <a href="https://x.com/menudealz/" className="twitter" target="_blank">
                    <i className="bi bi-twitter" />
                  </a> */}
                      <a
                        href="https://www.facebook.com/people/MenuDealz/61563780342517/"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="bi bi-facebook  footer-icon" />
                      </a>
                      <a
                        href="https://www.instagram.com/menudealz/"
                        className="instagram"
                        target="_blank"
                      >
                        <i className="bi bi-instagram  footer-icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/menu-dealz/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin  footer-icon" />
                      </a>
                      <a
                        href="https://www.youtube.com/@MenuDealz"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="bi bi-youtube  footer-icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="footer-divider d-lg-none" />

            {/* <div className="row gy-3">
              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-telephone icon" />
                <div>
                  <h4>Contact Us</h4>
                  <p>
                    {branchDetails && branchDetails.contactNumber && (
                      <a
                        href={`tel:${branchDetails.contactNumber}`}
                        className="a-tag"
                      >
                        {branchDetails.contactNumber}
                      </a>
                    )}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-envelope-at icon" />
                <div>
                  <h4>Email Us</h4>
                  
                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-flex">
                <i className="bi bi-geo-alt-fill icon" />
                <div>
                  <h4>Address</h4>
                  
                </div>
              </div>
            </div> */}
            {/* {isShowWorkingHours ? (
              <div style={{ margin: "10px 0px" }}>
                <hr />
                <h4>Working hours</h4>
                <div className="row">
  {branchDetails?.workingHour !== null && Array.isArray(branchDetails?.workingHour) &&

    
    Object.entries(
      (branchDetails.workingHour as Array<{ day: number; isOpen: boolean; startTime: string; endTime: string }>)
      .reduce((acc, item) => {
        const key = `${item.startTime}-${item.endTime}`;
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
      }, {} as Record<string, Array<{ day: number; isOpen: boolean; startTime: string; endTime: string }>>)
    ).map(([timeRange, items], index) => (
      <div key={index} className="col-lg-3 col-md-3">
        <div>
          <strong>
            
            {items.map(item => 
              item.day === 1 ? "Monday" :
              item.day === 2 ? "Tuesday" :
              item.day === 3 ? "Wednesday" :
              item.day === 4 ? "Thursday" :
              item.day === 5 ? "Friday" :
              item.day === 6 ? "Saturday" :
              item.day === 7 ? "Sunday" :
              ""
            ).join(" , ")}
          </strong>
        </div>

        <div>
          {items[0].isOpen
            ? <span>Closed</span>
            : <span>Opening time: {items[0].startTime} Closing time: {items[0].endTime}</span>
          }
        </div>
        <br className="transparent-hr" />
      </div>
    ))
  }
</div>

              </div>
            ) : (
              <></>
            )} */}
          </div>
          <div className="container">
            <div className="copyright">
              <strong>
                <span>
                  <a
                    href="https://www.innovatechsoft.com/"
                    className="a-tag"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4c4c4c", fontSize: "large" }}
                  >
                    <p>© 2024 | MenuDealz</p>
                    <p>Powered by Innovatech Software Solutions</p>
                  </a>{" "}
                </span>
              </strong>
            </div>
            <div className="footer-links">
              <div className="social-links d-flex justify-content-center">
                {/* <a
                  href="https://twitter.com/Innovatechsoft"
                  className="twitter"
                >
                  <i className="bi bi-twitter" />
                </a> */}
                <a
                  href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook footer-icon" />
                </a>
                <a
                  href="https://www.instagram.com/innovatech_software_solution/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram footer-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/innovatech-software-solution/"
                  className="linkedin"
                  target="_blank"
                >
                  <i className="bi bi-linkedin footer-icon" />
                </a>
                {/* <a
                  href="https://www.tiktok.com/@innovatech_software"
                  className="linkedin"
                >
                  <i className="bi bi-tiktok" />
                </a> */}
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer
          id="footer"
          className="footer"
          // style={{ backgroundColor: "#302730" }}
        >
          <div className="container footer-line">
            <div className="row gy-3">
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>CONTACT US</h4>
                  <p>
                    <a
                      href="tel:+971 50724 5494"
                      className="a-tag"
                      style={{ fontSize: "large" }}
                    >
                      <i className="bi bi-telephone icon" />
                      +971 50724 5494
                    </a>
                  </p>
                </div>
              </div>
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>EMAIL US</h4>
                  <p>
                    <a
                      href="mailto:team@menudealz.com"
                      className="a-tag"
                      style={{ fontSize: "large" }}
                    >
                      <i
                        className="bi bi-envelope-at icon"
                        style={{ color: "#4c4c4c" }}
                      />
                      team@menudealz.com
                    </a>
                  </p>
                </div>
              </div>
              <hr className="footer-divider d-lg-none" />
              <div className="col-lg-4 col-md-4 d-flex cef">
                <div>
                  <h4>FOLLOW US</h4>
                  <div className="social-links d-flex">
                    {/* <a href="https://x.com/menudealz/" className="twitter" target="_blank">
                    <i className="bi bi-twitter" />
                  </a> */}
                    <a
                      href="https://www.facebook.com/people/MenuDealz/61563780342517/"
                      className="facebook"
                      target="_blank"
                    >
                      <i className="bi bi-facebook  footer-icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/menudealz/"
                      className="instagram"
                      target="_blank"
                    >
                      <i className="bi bi-instagram  footer-icon" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/menu-dealz/"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin  footer-icon" />
                    </a>
                    <a
                      href="https://www.youtube.com/@MenuDealz"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="bi bi-youtube  footer-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-divider d-lg-none" />
          <div className="container">
            <div className="copyright">
              <strong>
                <span>
                  <a
                    href="https://www.innovatechsoft.com/"
                    className="a-tag"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#4c4c4c", fontSize: "large" }}
                  >
                    <p>© 2024 | MenuDealz</p>
                    <p>Powered by Innovatech Software Solutions</p>
                  </a>{" "}
                </span>
              </strong>
            </div>
            <div className="footer-links">
              <div className="social-links d-flex justify-content-center">
                {/* <a
                  href="https://twitter.com/Innovatechsoft"
                  className="twitter"
                >
                  <i className="bi bi-twitter" />
                </a> */}
                <a
                  href="https://www.facebook.com/people/InnovaTech-Software-Solution/61556855141266/"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook footer-icon" />
                </a>
                <a
                  href="https://www.instagram.com/innovatech_software_solution/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram footer-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/innovatech-software-solution/"
                  className="linkedin"
                  target="_blank"
                >
                  <i className="bi bi-linkedin footer-icon" />
                </a>
                {/* <a
                  href="https://www.tiktok.com/@innovatech_software"
                  className="linkedin"
                >
                  <i className="bi bi-tiktok" />
                </a> */}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}
