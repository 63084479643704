import React, { useState, useEffect } from "react";
import {
  CustomStringFormItem,
  StringFormItem,
} from "../inputs/StringFormItem";
import {
  Form,
  Button,
  Input
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import type { CheckboxChangeEvent } from "antd/es/checkbox";
// import {
//   UploadOutlined,
//   PlusOutlined,
//   DeleteOutlined,
// } from "@ant-design/icons";
// import dayjs from "dayjs";
import "./profile.scss";
import SideMenu from "../sidemenu";
import SideBarHeader from "../header/LoggedInHeader";
import { ToastContainer, toast } from "react-toastify";
import { currencyInterface } from "../registration";
// import { RootState } from "../redux/store/store";
// import { useSelector } from "react-redux";
import api from "../../api";
// import { RestaurantDetailInterface } from "../restaurant-list/interFace";
import Loader from "../Loader";
import SearchableDropdown from "./SearchableDropdown";
interface CurrencyOption {
  value: string;
  label: string;
}
function Profile() {
  const [showNav, setShowNav] = useState(true);
  const [disable] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [currencyData, setCurrencyData] = useState<currencyInterface[]>([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState<string | null>(null);
  const [restaurantId, setRestaurantId] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>();
  const [defaultCurrencyId, setDefaultCurrencyId] = useState("");
  // const [userData, setUserData] = useState(null);
  // const [formData, setFormData] = useState<RestaurantDetailInterface | null>(
  //   null
  // );
  const handleChange = (selectedOption: any) => {
    // setSelectedCurrencyOption(selectedOption.value);
    form.setFieldsValue({ defaultCurrencyId: selectedOption.value });
    setDefaultCurrencyId(selectedOption.value);
  };

  const updateRestaurant = async () => {
    try {
      const values = await form.validateFields();
      // This block is executed if the form validation succeeds.
      console.log("Success:", values);
      const payload = {
        ...values,
        cuisineTag: cuisineTags,  // Ensure cuisineTags is included in the format required
      };
  
      console.log("Payload:", payload);
      
      
      const response = await api.put(
        `/api/identity/restaurant/${restaurantId}`,
        payload
      );
      console.log(payload.defaultCurrencyId)
      console.log("update response", response.data);
      toast.success(response.data.message);
      if (payload.defaultCurrencyId) {
        const currencyResponse = await api.get(`/api/identity/currency/${payload.defaultCurrencyId}`);
        const currencyCode = currencyResponse.data.data.code;
  
        // Update local storage
        const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser') || '{}');
        loggedInUser.restaurantdefaultCurrencyCode = currencyCode;
        localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      }
      setTimeout(() => {
        navigate("/restaurants-info");
      }, 2000);
      
    } catch (error: any) {
      // Explicitly type 'error' as 'any'
      console.error("There was an error!", error);

      if (error.response) {
        // If the error has a response, extract the error message from the response data
        toast.error(error.response.data.message);
      } else {
        // If there is no response, display a generic error message
        toast.error("Please fill out all required fields.");
      }
    } 
  };

  // const enableForm = () => {
  //   console.log("enable form click");
  //   setDisable("");
  // };

  // fetch currency
  const currencyFetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/api/identity/currency/`);
      // console.log("currency response", response.data.data);
      setCurrencyOptions(
        response.data.data.map((item: { _id: any; name: any }) => ({
          value: item._id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loggedInUserData = localStorage.getItem("loggedInUser");
    if (loggedInUserData) {
      // setUserData(JSON.parse(loggedInUserData));
      const parsedData = JSON.parse(loggedInUserData);
      setRestaurantId(parsedData.restaurantId);
      fetchRestaurantDetailData(parsedData.restaurantId);
      console.log("restt idd " + restaurantId);
    }
    currencyFetchData();
  }, [restaurantId]);

  // fetch restaurant data with id
  // useEffect(() => {
  //   if (restaurantId !== null) {
  //     fetchRestaurantDetailData(restaurantId);
  //   }
  // }, [restaurantId]);

  const fetchRestaurantDetailData = async (id: string) => {
    console.log("restttt data fetchs");
    try {
      setLoading(true);
      const response = await api.get(`/api/identity/restaurant/${id}`);
      const restaurantDetailData = response.data.data;
      console.log("restaurantDetailData" + restaurantDetailData);
      form.setFieldsValue({
        isActive: restaurantDetailData.isActive,
        name: restaurantDetailData.name,
        brandName: restaurantDetailData.brandDetail.name,
        tagline: restaurantDetailData.tagline,
        ownerName: restaurantDetailData.owner.name,
        ownerContactNumber: restaurantDetailData.owner.contactNumber,
        ownerEmail: restaurantDetailData.owner.email,
        contactNumber: restaurantDetailData.contactNumber,
        mobileNumber: restaurantDetailData.mobileNumber,
        // email: restaurantDetailData.email,
        contactPersonName: restaurantDetailData.contactPerson.name,
        contactPersonContactNumber:
          restaurantDetailData.contactPerson.contactNumber,
        contactPersonEmail: restaurantDetailData.contactPerson.email,
        place: restaurantDetailData.place,
        defaultCurrencyId: restaurantDetailData.defaultCurrency._id,        // description: response.data.data.description,
      });
      // setFormData(restaurantDetailData || null);
      // setError(
      //   restaurantDetailData ? null : "No results found for the given ID."
      // );
      setCuisineTags(restaurantDetailData.cuisineTag || []);
      setDefaultCurrencyId(restaurantDetailData.defaultCurrency._id);
    } catch (error) {
      console.error("Error fetching restaurant detail data:", error);
      // setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const [cuisineTags, setCuisineTags] = useState([""]);

  const addCuisineTag = () => {
    setCuisineTags([...cuisineTags, ""]);
  };

  const removeCuisineTag = (index) => {
    const updatedTags = cuisineTags.filter((_, i) => i !== index);
    setCuisineTags(updatedTags);
  };

  const handleCuisineTagChange = (index, value) => {
    const updatedTags = [...cuisineTags];
    updatedTags[index] = value;
    setCuisineTags(updatedTags);
  };

  return (
    <>
      <div className={`body-area${showNav ? " body-pd" : ""}`}>
        <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
        <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
          <nav className="sideNav">
            <SideMenu />
          </nav>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="">
            <section id="book-a-table" className="book-a-table res ">
              <div className="container " data-aos="fade-up">
                <div className="row g-0">
                  <div className="col-lg-12  reservation-form-bg">
                    <div className="row d-flex justify-content-end update-rest">
                      <div className="col">
                        <h4>Update Restaurant </h4>
                      </div>
                      {/* <div className="col text-end ">
                        <Button
                          // icon={<ArrowRightOutlined />}
                          style={{ background: "#ce1212", color: "white" }}
                          // onClick={form.submit}
                          onClick={() => enableForm()}
                        >
                          <i className="bi bi-pencil-square text-align-center"></i>
                        </Button>
                      </div> */}
                    </div>

                    <Form
                      action="forms/book-a-table.php"
                      method="post"
                      role="form"
                      className="php-email-form"
                      data-aos="fade-up"
                      data-aos-delay={100}
                      form={form}
                      layout="vertical"
                      onFinish={updateRestaurant}
                    >
                      <>
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Restaurant Name"
                              name="name"
                              disabled={disable}
                              placeholder="Name of the restaurant *"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter restaurant name",
                                },
                              ]}
                            // initialValue={formData?.name ?? undefined}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Brand Name"
                              name="brandName"
                              disabled={disable}
                              placeholder="Name of the Brand *"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter brand name",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Tagline"
                              name="tagline"
                              disabled={disable}
                              placeholder="Tagline"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter tagline",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Owner’s Name"
                              name="ownerName"
                              disabled={disable}
                              placeholder="Owner’s Name *"
                              rules={[
                                {
                                  pattern: /^[a-zA-Z\s]+$/,
                                  message: "Name can only contain letters and spaces",
                                }
                              ]}
                            />

                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Owner's Phone number"
                              name="ownerContactNumber"
                              disabled={disable}
                              placeholder="+971XXXXXXXXXX *"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter phone number",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Owner’s Email ID"
                              name="ownerEmail"
                              disabled={disable}
                              placeholder="Owner’s Email ID *"
                              rules={[
                                {
                                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Email is not valid",
                                }
                              ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Restaurant Contact Number"
                              name="contactNumber"
                              disabled={disable}
                              placeholder="+971XXXXXXXXXX*"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Restaurant Contact Number",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Restaurant Mobile Number"
                              name="mobileNumber"
                              disabled={disable}
                              placeholder="+971XXXXXXXXXX*"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter phone number",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Place"
                              name="place"
                              disabled={disable}
                              placeholder="Place"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter place name *",
                              //   },
                              // ]}
                            />
                            <div className="validate" />
                          </div>
                          {/* <div className="col-lg-4 col-md-6">
                            <CustomCheckbox
                              name="isActive"
                              label="Restaurant Active Status"
                              disabled={disable}
                            />
                            <div className="validate" />
                          </div> */}
                        </div>

                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Contact Person Name"
                              name="contactPersonName"
                              disabled={disable}
                              placeholder="Contact Person Name *"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter contact person",
                                },
                                {
                                  pattern: /^[a-zA-Z\s]+$/,
                                  message: "Name can only contain letters and spaces",
                                }
                              ]}
                            />
                            <div className="validate" />
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="  Contact Person Mobile Number"
                              name="contactPersonContactNumber"
                              disabled={disable}
                              placeholder=" +971XXXXXXXXXX *"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Contact Number",
                                },
                              ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <StringFormItem
                              label="Contact person email ID"
                              name="contactPersonEmail"
                              disabled={disable}
                              placeholder="Contact person email ID *"
                              rules={[
                                {
                                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Email is not valid",
                                }
                              ]}
                            />
                            <div className="validate" />
                          </div>
                          <div className="col-lg-4 col-md-6">
                          <Form.Item label="Cuisine Tags">
                           {cuisineTags.map((tag, index) => (
                          <div key={index} style={{ display: "flex", marginBottom: 8 }}>
                            <Input
                              value={tag}
                              onChange={(e) => handleCuisineTagChange(index, e.target.value)}
                              placeholder="Enter a cuisine tag"
                              style={{ marginRight: 8 }}
                            />
                            {/* Only show MinusCircleOutlined if index is greater than 0 */}
                            {index > 0 && (
                              <MinusCircleOutlined
                                onClick={() => removeCuisineTag(index)}
                                style={{ color: "red" }}
                              />
                            )}
                            
                            {/* Show PlusCircleOutlined only in the first row */}
                            {index === 0 && (
                              <PlusCircleOutlined
                                onClick={addCuisineTag}
                                style={{ color: "red" }}
                              />
                            )}
                          </div>
                        ))}
                      </Form.Item>
                      <div className="validate" />
                        </div>

                          {/* <div className="col-lg-4 col-md-6">
                            {currencyData !== null && (
                              <CustomStringFormItem
                                style={{ backgroundColor: "red" }}
                                name="defaultCurrencyId"
                                disabled={disable}
                                label="Default Currency"
                                placeholder="Select Currency"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select currency",
                                  },
                                ]}
                                dropdownOptions={currencyData.map((item) => ({
                                  value: item._id,
                                  label: item.name,
                                }))}
                              />
                            )}
                            <div className="validate" />
                          </div> */}
                          <div className="col-lg-4 col-md-6">
                          <Form.Item label="Default Currency" name="defaultCurrencyId"
                          //  rules={[
                          //     {
                          //       required: true,
                          //       message: "Please enter currency",
                          //     },
                          //   ]}
                            >
                            <SearchableDropdown
                              name="defaultCurrencyId"
                              options={currencyOptions}
                              onChange={handleChange}
                              value={defaultCurrencyId}
                            />
                          </Form.Item>
                          
                        </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <Button
                            style={{
                              background: "#ce1212",
                              color: "white",
                              opacity: disable === "disabled" ? 0.5 : 1,
                              cursor:
                                disable === "disabled"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            onClick={
                              disable === "" ? updateRestaurant : undefined
                            }
                          >
                            Update
                          </Button>
                          <ToastContainer />
                        </div>
                      </>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;
