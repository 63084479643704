import React, { useEffect, useState } from "react";
import "./restaurantMenu.css";
import SideMenu from "../../sidemenu";
import SideBarHeader from "../../header/LoggedInHeader";

export default function RestaurantMenu() {
  const [plans, setPlans] = useState([]);
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    // Fetch subscription plans from the API
    const fetchPlans = async () => {
      try {
        const response = await fetch("/api/identity/subscription/active");
        const data = await response.json();
        if (response.ok) {
          setPlans(data.data);
        } else {
          console.error("Failed to fetch plans:", data.message);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchPlans();
  }, []);

const [show, setShow] = useState(false);
  const handleOpen = () => {
    // alert("Contact Us\n+971 50724 5494\nEmail Us\nsales@innovatechsoft.com");
    setShow(true);
  };
  const handleClose= () =>{
    setShow(false);
  }

  return (
    <>
      {/* ======= Menu Section ======= */}
      
      <div>
        <div className={`body-area${showNav ? " body-pd" : ""}`}>
          <SideBarHeader showNav={showNav} setShowNav={setShowNav} />
          <div className={`l-navbar${showNav ? " showSideNav" : ""}`}>
            <nav className="sideNav">
              <SideMenu />
            </nav>
          </div>
          <section id="menu" className="menu section-menu">
        <div className="container" data-aos="fade-up">
          <div className="container">
            <div className="row my-4">
              <div className="col">
                <h4>Subscription Plans</h4>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn"
                onClick={handleOpen}
                style={{
                  backgroundColor: 'rgb(254, 108, 0)', // Custom background color
                  color: 'white', 
                }}
              >
                Contact Us
              </button>
            </div>
            <div className="row">
              {plans.map((plan) => (
                <div className="col-sm-3 py-2" key={plan._id}>
                  <div className="plancard plancard-custom plancard-hover">
                    <div className="plancard-header">{plan.name}</div>
                    <div className="plancard-body">
                      <div className="price">{plan.price} AED/-</div>
                      <ul>
                        <li>{plan.description}</li>
                      </ul>
                      <button
                        type="button"
                        className="btn"
                        onClick={handleOpen}
                        style={{
                          backgroundColor: 'rgb(254, 108, 0)', // Custom background color
                          color: 'white', 
                        }}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>



        </div>
        
      </div>
      {show && (
        <div
          className="modal"
          tabIndex={-1}
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Contact Us
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="delete-text">
                {" "}
                Contact Us :- +971 50724 5494
                <br />
                Email Us :- team@menudealz.com
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  OK
                </button>
                
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Menu Section */}
    </>
  );
}
