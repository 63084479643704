import React, { useEffect, useRef, useState } from "react";
import "./restaurantMenu.scss";
import Banner1 from "./img/menu/BANNER 4.jpeg";
import Banner2 from "./img/menu/BANNER 5.png";
import Banner3 from "./img/menu/CURRY MARIA 2.jpeg";
import Banner4 from "./img/menu/BANNER 6.png";
import locationIcon from "../logo/Location Icon.png";
import WhatsaapIcon from "../logo/Whatsapp Icon.png";
import PhoneIcon from "../logo/Phone Icon.png";

import defLogo from "../logo/gallery-4.jpg";
import api from "../../../api";
import Loader from "../../Loader";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { FaPhoneAlt, FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";

interface DishCardProps {
  photo: string;
  description: string;
}
interface Testimonial {
  title: string;
  content: string;
  author: string;
}
const CustomerHomePage = () => {
  const [activeTab, setActiveTab] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [branches, setBranches] = useState([]);
  const [comboSpecialOffers, setComboSpecialOffers] = useState([]);
  const [discountedSpecialOffers, setDiscountedSpecialOffers] = useState([]);
  const [eventSpecialOffers, setEventSpecialOffers] = useState([]);
  const [todaySpecialOffers, setTodaySpecialOffers] = useState([]);
  const [loading, setLoading] = useState(true); // For handling loading state
  const [error, setError] = useState(null); // For handling errors
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 100;
  const OFFER_PER_PAGE = 4;
  const [showAll, setShowAll] = useState(false);
  const [showAllRestaurant, setShowAllRestaurant] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [currentPlaceholder, setCurrentPlaceholder] =
    useState("Search by dish...");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [restLogo, setRestLogos] = useState([]);

  const placeholders = [
    "Search by dish...",
    "Search by restaurant...",
    "Search by location...",
  ];

  // Cycle through placeholders every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [placeholders.length]);

  useEffect(() => {
    setCurrentPlaceholder(placeholders[placeholderIndex]);
  }, [placeholderIndex]);

  const toggleMenu = () => {
    console.log(isMenuOpen);
    setIsMenuOpen(!isMenuOpen); // This will toggle between open and close
    console.log(isMenuOpen);
  };
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    // Request user's location when the component mounts
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          console.log({ latitude, longitude });
        },
        // (error) => {
        //   message.error("Location access denied by user.");
        // }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "/api/distributor/restaurant/?page=1&limit=100"
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setRestLogos(result.data.restaurants);
        console.log("*123", result.data.restaurants);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  const fetchData = async (
    page,
    searchQuery = searchText,
    userLatitude = null,
    userLongitude = null
  ) => {
    try {
      setLoading(true);
      const response = await api.get(
        `/api/distributor/branch-menu/?page=${page}&limit=${ITEMS_PER_PAGE}&searchText=${searchQuery}&userLatitude=${userLatitude}&userLongitude=${userLongitude}`
      );

      const data = response.data.data.assignment;

      setRestaurants(data.restaurants);
      console.log("Restaurant details ", data.restaurants);
      setBranches(data.branches);
      setComboSpecialOffers(data.comboSpecialOffers);
      setDiscountedSpecialOffers(data.discountedSpecialOffers);
      setTodaySpecialOffers(data.todaySpecialOffers);
      setEventSpecialOffers(data.specialEventMenus);

      console.log("comboSpecialOffers", data.comboSpecialOffers);
      console.log("discountedSpecialOffers", data.discountedSpecialOffers);
      console.log("todaySpecialOffers", data.todaySpecialOffers);
      console.log("specialEventMenus", data.specialEventMenus);

      if (
        data.discountedSpecialOffers &&
        data.discountedSpecialOffers.length > 0
      ) {
        setActiveTab("Flash Dealz");
      } else if (
        data.comboSpecialOffers &&
        data.comboSpecialOffers.length > 0
      ) {
        setActiveTab("Combo Dealz");
      } else if (data.TodaySpecial && data.TodaySpecial.length > 0) {
        setActiveTab("Today Special");
      } else if (data.EventSpecial && data.EventSpecial.length > 0) {
        setActiveTab("Event Special");
      } else {
        setActiveTab(""); // No offers available
      }

      setLoading(false); // Data is loaded
    } catch (err) {
      setError(err.message);
      setLoading(false); // Stop loading if there's an error
    }
  };
  // Initial API call without coordinates
  useEffect(() => {
    fetchData(currentPage, searchText);
  }, [currentPage, searchText]);

  // Trigger API call when coordinates become available
  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetchData(currentPage, searchText, latitude, longitude);
    }
  }, [latitude, longitude, currentPage, searchText]);

  // Handle search button click
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    fetchData(1, searchText, latitude, longitude); // Fetch with coordinates if available
  };

  // Handle search input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value === "") {
      setCurrentPage(1); // Reset to first page
      fetchData(1, "", latitude, longitude); // Fetch with empty search query
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setShowAll(false); // Reset the showAll state when switching tabs
  };

  const viewBranchMenubyBranch = (
    menuId: string,
    branchId: string,
    restaurantId: string
  ) => {
    navigate(
      "/restaurant?a=" +
        menuId +
        "&b=" +
        branchId +
        "&c=" +
        restaurantId +
        "&d="
    );
  };
  const viewBranchMenu = (
    menuId: string,
    branchId: string,
    restaurantId: string
  ) => {
    console.log(
      "branchId: " +
        branchId +
        " menuId: " +
        menuId +
        " restautantId: " +
        restaurantId
    );
    navigate(
      "/restaurant?a=" +
        menuId +
        "&b=" +
        branchId +
        "&c=" +
        restaurantId +
        "&d=" +
        activeTab
    );
  };

  const renderOffers = (offers) => {
    // Determine the number of offers to display based on the showAll state
    const offersToDisplay = showAll ? offers : offers?.slice(0, OFFER_PER_PAGE);

    return offersToDisplay
      ?.filter((offer) => offer.imageAttachement?.[0]?.url)
      ?.map((offer, index) => (
        <div className="cursor-pointer">
          <div
            className="card responsive-card"
            onClick={() =>
              viewBranchMenu(offer._id, offer.branchId, offer.restaurantId)
            }
          >
            <div
              className="offer-image"
              style={{ position: "relative", width: "100%", height: "250px" }}
            >
              <img
                src={offer.imageAttachement[0].url} // Your image source
                alt={offer.name}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  objectPosition: "center",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "80px",
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), transparent)",
                  display: "flex",
                  alignItems: "flex-end",
                  padding: "0px 0px 10px 0px",
                  color: "white",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-2 restaurant-icon">
                      <img src={offer.restaurantLogoUrl} alt="Chef Icon" />
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col">
                          <span
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            {offer.restaurantName.length > 23
                              ? `${offer.restaurantName.substring(0, 23)}...`
                              : offer.restaurantName}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span style={{ fontSize: "15px" }}>
                            {offer.branchName.length > 20
                              ? `${offer.branchName.substring(0, 20)}...`
                              : offer.branchName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="container" style={{ padding: "10px" }}>
              <div className="row">
                <div className="col">
                  <text style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {offer?.name?.length > 30
                      ? offer?.name?.substring(0, 30) + "..."
                      : offer?.name}
                  </text>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  {offer?.offerPrice > 0 && (
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "larger",
                      }}
                    >
                      AED {offer.offerPrice}
                    </span>
                  )}
                  {offer?.totalPrice > 0 && (
                    <span
                      style={{
                        textDecoration: "line-through",
                        color: "red",
                        marginLeft: "8px",
                        fontSize: "medium",
                      }}
                    >
                      AED {offer.totalPrice}
                    </span>
                  )}
                </div>
                <div className="col-4">
                  <div className="row" style={{ gap: "0" }}>
                    {" "}
                    {/* Ensure no gap between columns */}
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`https://www.google.com/maps?q=${offer.branchLocation.latitude},${offer.branchLocation.longitude}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation to parent onClick
                      >
                        <img src={locationIcon} alt="locationIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`tel:${offer.branchContactNumber}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={PhoneIcon} alt="PhoneIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        // href={`https://wa.me/${offer.branchContactNumber}`}
                        href={`https://wa.me/${offer.branchContactNumber}?text=${encodeURIComponent(
                          `This Chat is initiated from MenuDealz. You are now going to start a WhatsApp chat directly with the restaurant. For any issues with your ordered food, please contact the restaurant directly. You can also share your feedback with Menu Dealz.
Thank you for using Menu Dealz!

Enquired Item:
    •    Item Name: ${offer.name}
    •    Price: ${offer.offerPrice}.`
                        )}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={WhatsaapIcon} alt="WhatsaapIcon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  };
  const renderEvent = (offers) => {
    const offersToDisplay = showAll ? offers : offers?.slice(0, OFFER_PER_PAGE);

    return offersToDisplay
      ?.filter(
        (offer) =>
          offer.imageAttachement?.[0]?.url ||
          offer.dishImageAttachement?.[0]?.url
      )
      ?.map((offer, index) => (
        <div className="event-card-horizontal" key={index}>
          <div
            className="event-card-horizontal-content"
            onClick={() =>
              viewBranchMenu(offer._id, offer.branchId, offer.restaurantId)
            }
          >
            {/* Left Section: Image */}
            <div className="event-card-horizontal-image">
              <img
                src={
                  offer.imageAttachement?.[0]?.url ||
                  offer.dishImageAttachement?.[0]?.url
                }
                alt={offer.name}
                className="event-card-horizontal-offer-image"
              />
            </div>

            {/* Right Section: Details */}
            <div className="event-card-horizontal-details">
            <div className="container">
              <div className="row">
                <div className="col">
                  <text style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {offer?.name?.length > 30
                      ? offer?.name?.substring(0, 30) + "..."
                      : offer?.name}
                  </text>
                </div>
              </div>
              <div className="row">
              <p className="event-card-horizontal-price">
                <span style={{ color: "green", fontWeight: "bold" }}>
                  AED {offer.totalPrice || offer.offerPrice}
                </span>
              </p>
                
              </div>
              <div className="row"><div className="col">
                  <div className="row" style={{ gap: "0" }}>
                    {" "}
                    {/* Ensure no gap between columns */}
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`https://www.google.com/maps?q=${offer.branchLocation.latitude},${offer.branchLocation.longitude}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation to parent onClick
                      >
                        <img src={locationIcon} alt="locationIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`tel:${offer.branchContactNumber}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={PhoneIcon} alt="PhoneIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        // href={`https://wa.me/${offer.branchContactNumber}`}
                        href={`https://wa.me/${offer.branchContactNumber}?text=${encodeURIComponent(
                          `This Chat is initiated from MenuDealz. You are now going to start a WhatsApp chat directly with the restaurant. For any issues with your ordered food, please contact the restaurant directly. You can also share your feedback with Menu Dealz.
Thank you for using Menu Dealz!

Enquired Item:
    •    Item Name: ${offer.name}
    •    Price: ${offer.offerPrice}.`
                        )}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={WhatsaapIcon} alt="WhatsaapIcon" />
                      </a>
                    </div>
                  </div>
              </div></div>
              <hr />
              <div className="container">
                  <div className="row">
                    <div className="col restaurant-icon">
                      <img src={offer.restaurantLogoUrl} alt="Chef Icon" />
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            {offer.restaurantName.length > 15
                              ? `${offer.restaurantName.substring(0, 15)}...`
                              : offer.restaurantName}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <span style={{ fontSize: "15px" }}>
                            {offer.branchName.length > 15
                              ? `${offer.branchName.substring(0, 15)}...`
                              : offer.branchName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
            </div>
          </div>
        </div>
      ));
  };
  const renderSpecial = (offers) => {
    // Determine the number of offers to display based on the showAll state
    const offersToDisplay = showAll ? offers : offers?.slice(0, OFFER_PER_PAGE);
    console.log("---->", offersToDisplay);

    return offersToDisplay
      ?.filter(
        (offer) =>
          offer.imageAttachement?.[0]?.url ||
          offer.dishImageAttachement?.[0]?.url
      )
      ?.map((offer, index) => (
        // <div className="cursor-pointer">
        //   <div
        //     className="card responsive-card"
        //     onClick={() =>
        //       viewBranchMenu(offer._id, offer.branchId, offer.restaurantId)
        //     }
        //   >
        //     <div className="bottom-section">
        //       <div className="restaurant-icon">
        //         <img src={offer.restaurantLogoUrl} alt="Chef Icon" />
        //       </div>
        //       <div className="restaurant-name">
        //         {offer.restaurantName.length > 30
        //           ? `${offer.restaurantName.substring(0, 30)}...`
        //           : offer.restaurantName}{" "}
        //         <br />
        //         {offer.branchName.length > 30
        //           ? `${offer.branchName.substring(0, 30)}...`
        //           : offer.branchName}
        //       </div>
        //     </div>

        //     <div className="offer-image">
        //       <img
        //         src={
        //           offer.imageAttachement?.[0]?.url ||
        //           offer.dishImageAttachement?.[0]?.url
        //         }
        //         alt={offer.name}
        //         style={{
        //           height: "250px",
        //           borderBottomLeftRadius: "8px",
        //           borderBottomRightRadius: "8px",
        //           objectPosition: "center",
        //         }}
        //       />
        //       <div className="price-overlay">
        //         <span style={{ color: "#333" }}>
        //           AED {offer.totalPrice || offer.offerPrice}
        //         </span>
        //       </div>
        //       <div className="icon-overlay">
        //         <div className="icon-circle">
        //           <a
        //             href={`https://www.google.com/maps?q=${offer.branchLocation.latitude},${offer.branchLocation.longitude}`}
        //             className="a-tag"
        //             target="_blank"
        //             onClick={(event) => event.stopPropagation()}
        //           >
        //             <FaMapMarkerAlt className="offer-icon" />
        //           </a>
        //         </div>
        //         <div className="icon-circle">
        //           <a
        //             href={`tel:${offer.branchContactNumber}`}
        //             className="a-tag"
        //             target="_blank"
        //             onClick={(event) => event.stopPropagation()}
        //           >
        //             <FaPhoneAlt className="offer-icon" />
        //           </a>
        //         </div>
        //         <div className="icon-circle">
        //           <a
        //             href={`https://wa.me/${offer.branchContactNumber}`}
        //             className="a-tag"
        //             target="_blank"
        //             onClick={(event) => event.stopPropagation()}
        //           >
        //             <FaWhatsapp
        //               className="offer-icon"
        //               style={{ fontSize: "25px" }}
        //             />
        //           </a>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="card-bottom-section">
        //       <h6 style={{ fontSize: "0.9rem" }}>
        //         {offer?.name?.substring(0, 30) ||
        //           offer?.dishName?.substring(0, 30)}
        //       </h6>
        //     </div>
        //   </div>
        // </div>
        <div className="cursor-pointer">
          <div
            className="card responsive-card"
            onClick={() =>
              viewBranchMenu(offer._id, offer.branchId, offer.restaurantId)
            }
          >
            <div
              className="offer-image"
              style={{ position: "relative", width: "100%", height: "250px" }}
            >
              <img
                src={
                  offer.imageAttachement?.[0]?.url ||
                  offer.dishImageAttachement?.[0]?.url
                }
                alt={offer.name}
                style={{
                  // objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  objectPosition: "center",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "80px",
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), transparent)",
                  display: "flex",
                  alignItems: "flex-end",
                  padding: "0px 0px 10px 0px",
                  color: "white",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-2 restaurant-icon">
                      <img src={offer.restaurantLogoUrl} alt="Chef Icon" />
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col">
                          <span
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            {offer.restaurantName.length > 23
                              ? `${offer.restaurantName.substring(0, 23)}...`
                              : offer.restaurantName}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <span style={{ fontSize: "15px" }}>
                            {offer.branchName.length > 20
                              ? `${offer.branchName.substring(0, 20)}...`
                              : offer.branchName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="container" style={{ padding: "10px" }}>
              <div className="row">
                <div className="col">
                  <text style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {offer?.name?.substring(0, 30) ||
                      offer?.dishName?.substring(0, 30)}
                  </text>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <span
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "larger",
                    }}
                  >
                    AED {offer.totalPrice || offer.offerPrice}
                  </span>
                </div>
                <div className="col-4">
                  <div className="row" style={{ gap: "0" }}>
                    {" "}
                    {/* Ensure no gap between columns */}
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`https://www.google.com/maps?q=${offer.branchLocation.latitude},${offer.branchLocation.longitude}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation to parent onClick
                      >
                        <img src={locationIcon} alt="locationIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        href={`tel:${offer.branchContactNumber}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={PhoneIcon} alt="PhoneIcon" />
                      </a>
                    </div>
                    <div className="col p-0">
                      {" "}
                      {/* Remove padding */}
                      <a
                        // href={`https://wa.me/${offer.branchContactNumber}`}
                        href={`https://wa.me/${offer.branchContactNumber}?text=${encodeURIComponent(
                          `This Chat is initiated from MenuDealz. You are now going to start a WhatsApp chat directly with the restaurant. For any issues with your ordered food, please contact the restaurant directly. You can also share your feedback with Menu Dealz.
Thank you for using Menu Dealz!

Enquired Item:
    •    Item Name: ${offer.name}
    •    Price: ${offer.offerPrice}.`
                        )}`}
                        className="a-tag"
                        target="_blank"
                        onClick={(event) => event.stopPropagation()} // Stop click propagation
                      >
                        <img src={WhatsaapIcon} alt="WhatsaapIcon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
  };

  const renderBranches = (offers) => {
    // Determine the number of offers to display based on the showAllRestaurant state
    const offersToDisplay = showAllRestaurant
      ? offers
      : offers?.slice(0, OFFER_PER_PAGE);

    return offersToDisplay?.map((offer, index) => (
      <div className="cursor-pointer" key={index}>
        <div
          className="card responsive-card custom-dish-card"
          style={{ alignItems: "center" }}
          onClick={() =>
            viewBranchMenubyBranch(offer._id, offer._id, offer.restaurantId)
          }
        >
          {/* <div className="restaurant-bottom-section">
            <div className="restaurant-name">
              {offer.restaurantName} <br /> {offer.name}
            </div>
          </div> */}

          {/* Dish Image */}
          <div
            className="offer-image"
            style={{ position: "relative", width: "100%", height: "250px" }}
          >
            <img
              src={offer.restaurantLogoUrl || defLogo}
              alt={offer.name}
              style={{
                // objectFit: "cover",
                width: "100%",
                height: "100%",
                objectPosition: "center",
              }}
            />
          </div>

          <div
            className="container"
            style={{ padding: "10px", height: "125px" }}
          >
            <div className="row" style={{ height: "62px" }}>
              <div className="col-2 restaurant-icon">
                <img src={offer.restaurantLogoUrl || defLogo} alt="Chef Icon" />
              </div>
              <div className="col-10">
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {offer.restaurantName.length > 40
                    ? `${offer.restaurantName.substring(0, 40)}...`
                    : offer.restaurantName}{" "}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <span style={{ fontSize: "18px" }}>
                  {offer.restaurantCuisineTag.filter((tag) => tag.trim() !== "")
                    .length > 0
                    ? offer.restaurantCuisineTag
                        .filter((tag) => tag.trim() !== "")
                        .join(" | ").length > 35
                      ? offer.restaurantCuisineTag
                          .filter((tag) => tag.trim() !== "")
                          .join(" | ")
                          .substring(0, 35) + "..."
                      : offer.restaurantCuisineTag
                          .filter((tag) => tag.trim() !== "")
                          .join(" | ")
                    : ""}
                </span>
              </div>
              <div className="col-4">
                <div className="row" style={{ gap: "0" }}>
                  {" "}
                  <div className="col p-0">
                    {" "}
                    <a
                      href={`https://www.google.com/maps?q=${offer?.branchLocation?.latitude},${offer?.branchLocation?.longitude}`}
                      className="a-tag"
                      target="_blank"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <img src={locationIcon} alt="locationIcon" />
                    </a>
                  </div>
                  <div className="col p-0">
                    {" "}
                    {/* Remove padding */}
                    <a
                      href={`tel:${offer.branchContactNumber}`}
                      className="a-tag"
                      target="_blank"
                      onClick={(event) => event.stopPropagation()} // Stop click propagation
                    >
                      <img src={PhoneIcon} alt="PhoneIcon" />
                    </a>
                  </div>
                  <div className="col p-0">
                    {" "}
                    {/* Remove padding */}
                    <a
                      href={`https://wa.me/${offer.branchContactNumber}`}
                      className="a-tag"
                      target="_blank"
                      onClick={(event) => event.stopPropagation()} // Stop click propagation
                    >
                      <img src={WhatsaapIcon} alt="WhatsaapIcon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const handleViewAllToggle = () => {
    setShowAll((prev) => !prev); // Toggle the showAll state
  };
  const handleViewAllRestaurantToggle = () => {
    setShowAllRestaurant((prev) => !prev); // Toggle the showAll state
  };

  const dishes = [
    {
      photo:
        "https://media.self.com/photos/57d8952946d0cb351c8c50c9/master/w_1600%2Cc_limit/DELICIOUS-1-POT-Lentil-and-Black-Bean-Chili-Smoky-hearty-PROTEIN-and-fiber-packed-vegan-glutenfree-lentils-chili-healthy-recipe2.jpg",
      description: "Tasty, Yummy, Delicious, Delectable, Appetizing",
    },
    {
      photo:
        "https://res.cloudinary.com/rainforest-cruises/images/c_fill,g_auto/f_auto,q_auto/w_1120,h_732,c_fill,g_auto/v1661887113/indian-food/indian-food-1120x732.jpg",
      description: "Nutritious, Satisfying, Savory, Tasty, Yummy",
    },
    {
      photo:
        "https://plus.unsplash.com/premium_photo-1673590981774-d9f534e0c617?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZGlzaHxlbnwwfHwwfHx8MA%3D%3D",
      description: "Honeyed, Juicy, Scrumptious, Luscious.",
    },
    {
      photo: "	https://c.ndtvimg.com/gws/1855/assets/1.jpeg?1612944800",
      description: "Creamy, Rich, Scrumptious, Flavorsome",
    },
  ];
  const testimonials: Testimonial[] = [
    {
      title: "TRANSPORTED TO PARIS",
      content:
        "One bite and I felt like I was back in France. Chef Ingrid and her team have outdone themselves!",
      author: "Teddy, Entrepreneur",
    },
    {
      title: "LOVELY BISTRO",
      content:
        "The ambience was great - and the food even better! Simple recipes done well, with top-notch ingredients. Will be back!",
      author: "Dani, Mother",
    },
    {
      title: "TRANSPORTED TO PARIS",
      content:
        "Eating here made me miss Paris so much. What a treat! I will be bringing my client meetings here.",
      author: "Carly, Entrepreneur",
    },
  ];
  const staticLogos = [
    "https://img.freepik.com/premium-vector/restaurant-logo-design_636083-178.jpg",
    "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/fast-food-restaurant-logo%2C-restaurant-logo-design-template-33255790cb8e1186b28609dd9afd4ee6_screen.jpg?ts=1668794604",
    "https://bcassetcdn.com/public/blog/wp-content/uploads/2019/07/18094833/the-red-cafe.png",
    "https://media.istockphoto.com/id/981368726/vector/restaurant-food-drinks-logo-fork-knife-background-vector-image.jpg?s=612x612&w=0&k=20&c=9M26CBkCyEBqUPs3Ls5QCjYLZrB9sxwrSFmnAmNCopI=",
    "https://png.pngtree.com/png-clipart/20220903/ourmid/pngtree-chef-restaurant-logo-png-image_6136204.png",
    "https://img.freepik.com/free-vector/gradient-restaurant-logo-design_23-2151257068.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1722729600&semt=ais_hybrid",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const visibleRestaurants = restLogo.slice(currentIndex, currentIndex + 5);
  // console.log("visibleRestaurants-->" , visibleRestaurants)
  if (visibleRestaurants.length < 10) {
    visibleRestaurants.push(
      ...restLogo.slice(0, 10 - visibleRestaurants.length)
    );
  }

  const localities = [
    {
      name: "Mumbai",
      image:
        "https://in.bmscdn.com/m6/images/common-modules/regions/mumbai.png",
    },
    {
      name: "Delhi-NCR",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/ncr.png",
    },
    {
      name: "Bengaluru",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/bang.png",
    },
    {
      name: "Hyderabad",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/hyd.png",
    },
    {
      name: "Ahmedabad",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/ahd.png",
    },
    {
      name: "Chandigarh",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/chd.png",
    },
    {
      name: "Chennai",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/chen.png",
    },
    {
      name: "Pune",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/pune.png",
    },
    {
      name: "Kolkata",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/kolk.png",
    },
    {
      name: "Kochi",
      image: "https://in.bmscdn.com/m6/images/common-modules/regions/koch.png",
    },
  ];
  const [numOffers, setNumOffers] = useState(window.innerWidth > 768 ? 5 : 1);
  const handleResize = () => {
    setNumOffers(window.innerWidth > 768 ? 5 : 1);
  };

  return (
    <div style={{ fontFamily: "'Mulish', sans-serif" }}>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center border-bottomHeader"
        style={{ borderBottom: "0.5px solid #eeebeb" }}
      >
        <div className="container d-flex justify-content-between ">
          <div className="d-flex">
            <a
              style={{ textDecoration: "none" }}
              href="/"
              className="logo d-flex align-items-center me-auto me-lg-0"
            >
              <div
                className="h-100 "
                style={{ width: "40px", alignContent: "center" }}
              >
                <img
                  src="/img/Menu Dealz Logo-01.jpg"
                  alt="logo"
                  style={{ objectFit: "cover", height: "40px" }}
                />
              </div>
              <h1 className="logotext">
                <span style={{ color: "#129901" }}>MENU</span>
                <span style={{ color: "#fe6c00" }}>DEALZ</span>
              </h1>
            </a>
          </div>

          <form className="search-form" onSubmit={handleSearch}>
            <input
              className="search-input"
              type="text"
              value={searchText}
              onChange={handleInputChange}
              placeholder={currentPlaceholder}
            />
            <button className="search-button" type="submit">
              <i className="bi bi-search"></i>
            </button>
          </form>
          {/* .navbar */}
          <div className="d-flex align-items-center">
            <nav className="header-nav">
              <div className="nav-links">
                {/* <Link to="/" className="header-nav-link">
                    Home
                  </Link> */}
                &emsp;
                <Link to="/contact-us" className="header-nav-link">
                  Contact Us
                </Link>
              </div>

              <div className="nav-buttons">
                <Link to="/login" className="header-nav-button login-button">
                  Login
                </Link>
                <Link
                  to="/home"
                  className="header-nav-button create-menu-button"
                >
                  Register as Restaurant
                </Link>
              </div>

              {/* Hamburger or Cross icon for small screens */}
              <i
                className={`bi bi-list hamburger-icon`}
                onClick={toggleMenu} // Toggle dropdown on click
              ></i>
            </nav>

            {/* Dropdown menu (only visible on small screens when the hamburger is clicked) */}
            {isMenuOpen && (
              <div className="dropdown-menu" ref={dropdownRef}>
                <Link to="/" className="dropdown-link" onClick={toggleMenu}>
                  Home
                </Link>
                <Link
                  to="/contact-us"
                  className="dropdown-link"
                  onClick={toggleMenu}
                >
                  Contact Us
                </Link>
                <Link
                  to="/login"
                  className="dropdown-link"
                  onClick={toggleMenu}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="dropdown-link"
                  onClick={toggleMenu}
                >
                  Register as Restaurant
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>
      <div className="menu-dealz">
        <div className="container my-4" style={{ paddingTop: "60px" }}>
          <div className="row">
            <div className="col-lg-9">
              <div
                id="carouselExampleControls"
                className="carousel slide carousel-container"
                data-bs-ride="carousel"
                data-bs-interval="2000"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={Banner1}
                      className="d-block w-100 carousel-image"
                      alt="Offer Banner 1"
                    />
                    {/* <div className="carousel-caption d-md-block">
                      <div className="bg-dark bg-opacity-50 p-2 rounded">
                        <h3 className="text-white">Offer Banner 1 !</h3>
                        <button className="btn btn-light mt-2">
                          View Offers
                        </button>
                      </div>
                    </div> */}
                  </div>

                  <div className="carousel-item">
                    <img
                      src={Banner2}
                      className="d-block w-100 carousel-image"
                      alt="Offer Banner 2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={Banner3}
                      className="d-block w-100 carousel-image"
                      alt="Offer Banner 2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={Banner4}
                      className="d-block w-100 carousel-image"
                      alt="Offer Banner 2"
                    />
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only"></span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleControls"
                  role="button"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only"></span>
                </a>
              </div>
            </div>

            <div
              className="col-lg-3 d-flex align-items-center justify-content-center sidebaneer"
              style={{ borderRadius: "15px", backgroundColor: " #f0e5d8" }}
            >
              <div className="text-center">
                <h1 className="text-dark">Discover Exclusive Deals</h1>
                <h6 className="text-muted">
                  Stay tuned for our exclusive offers and discounts on various
                  dishes. Enjoy your meal with the best deals!
                </h6>
                <button className="btn btn-outline-primary">Learn More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="view-more-container">
          <h2>Our Partners</h2>
        </div>
        {/* Scrollable Client Logos */}
        <div className="container my-4 overflow-hidden logoscroll">
          <div className="logo-scroll">
            {visibleRestaurants
              .concat(visibleRestaurants)
              .map((restaurant, index) => (
                <div className="logosimg" key={index}>
                  <img
                    src={
                      restaurant.logoUrl ||
                      staticLogos[index % staticLogos.length]
                    }
                    alt={restaurant.name}
                    className="img-fluid"
                  />
                </div>
              ))}
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="offers-container">
              <div className="view-more-container">
                <h1>OFFERS</h1>
              </div>

              <div className="offers-tabs">
                {discountedSpecialOffers?.length > 0 && (
                  <button
                    className={`tab-button ${
                      activeTab === "Flash Dealz" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Flash Dealz")}
                  >
                    Flash Dealz
                  </button>
                )}
                {comboSpecialOffers?.length > 0 && (
                  <button
                    className={`tab-button ${
                      activeTab === "Combo Dealz" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Combo Dealz")}
                  >
                    Combo Dealz
                  </button>
                )}

                {todaySpecialOffers?.length > 0 && (
                  <button
                    className={`tab-button ${
                      activeTab === "Today Special" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Today Special")}
                  >
                    Today's Special
                  </button>
                )}
                {eventSpecialOffers?.length > 0 && (
                  <button
                    className={`tab-button ${
                      activeTab === "Event Special" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("Event Special")}
                  >
                    Event Specialz
                  </button>
                )}
              </div>
              <div className={`offers-grid ${activeTab === "Event Special" ? "event-grid" : ""}`}>
                {activeTab === "Combo Dealz" &&
                  renderOffers(comboSpecialOffers)}
                {activeTab === "Flash Dealz" &&
                  renderOffers(discountedSpecialOffers)}
                {activeTab === "Today Special" &&
                  renderSpecial(todaySpecialOffers)}
                {activeTab === "Event Special" &&
                  renderEvent(eventSpecialOffers)}
                {activeTab === "" && <p>No offers available</p>}
              </div>
              {activeTab &&
                ((activeTab === "Combo Dealz" &&
                  comboSpecialOffers.length > 4) ||
                  (activeTab === "Flash Dealz" &&
                    discountedSpecialOffers.length > 4) ||
                  (activeTab === "Today Special" &&
                    todaySpecialOffers.length > 4) ||
                  (activeTab === "Event Special" &&
                    eventSpecialOffers.length > 4)) && (
                  <div className="view-more-container">
                    <button
                      className="view-more-button"
                      onClick={handleViewAllToggle}
                    >
                      <h5>
                        {showAll ? (
                          <>
                            <i className="bi bi-chevron-left"></i> View Less
                          </>
                        ) : (
                          <>
                            View More <i className="bi bi-chevron-right"></i>
                          </>
                        )}
                      </h5>
                    </button>
                  </div>
                )}
            </div>
            <br />
            <div className="restaurants-container">
              <div className="view-more-container">
                <h1 style={{ color: "#000000" }}>RESTAURANTS</h1>
              </div>

              <div className="offers-grid">{renderBranches(branches)}</div>
              <div className="view-more-container">
                {branches?.length > 4 && (
                  <button
                    className="view-more-button"
                    onClick={handleViewAllRestaurantToggle}
                  >
                    <h5>
                      {showAllRestaurant ? (
                        <>
                          <i className="bi bi-chevron-left"></i> View Less
                        </>
                      ) : (
                        <>
                          View More <i className="bi bi-chevron-right"></i>
                        </>
                      )}
                    </h5>
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        <br />
        {/*  Location Section */}
        {/* <div className="container my-4">
        <div className="row text-center">
          <h1 className="text-center my-5">LOCALITIES</h1>
          <div className="location-container">
            {localities.map((location, index) => (
              <div key={index} className="location-item">
                <img
                  src={location.image}
                  alt={location.name}
                  className="location-img"
                />
                <p className="location-name">{location.name}</p>
              </div>
            ))}
          </div>
        </div>
        <button className="all-restaurant-button">View All Localities</button>
      </div> */}
        <br />
        {/*  Dish Section */}
        {/* <div className="dish-container">
        <div className="container">
          <h1 className="text-center my-5">POPULAR DISHES</h1>
          <div className="row">
            {dishes.map((dish, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                <div className="dish-card">
                  <img
                    src={dish.photo}
                    alt="Dish Photo"
                    className="dish-photo"
                  />
                  <div className="dish-content">
                    
                    <p className="dish-description">{dish.description}</p>
                  
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="all-restaurant-button">View All Dishes</button>
      </div> */}

        {/* <br />
      <div className="testimonials-section">
        <h1 className="text-center">WHAT OUR CLIENTS SAY</h1>
        <div className="row justify-content-center">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial">
              <div className="quote-icon">“</div>
              <h3>{testimonial.title}</h3>
              <p>{testimonial.content}</p>
              <p className="author">- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default CustomerHomePage;
